﻿.creationDossier__trajet {
	section {
		margin-bottom: 30px;
	}

	h4.title--red {
		margin-bottom: 10px;
	}

	.folders__addButton {
		padding: 10px 0;
	}

	.inputFile__buttons label {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.creationDossier__trajetEtape {
	margin-bottom: 10px;

	.creationDossier__addElementButton {
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
	}
}

.rowTrajet {
	align-items: flex-start;
}

.rowTrajet .creationDossier__trajetDestination {
	padding-bottom: 0;

	.InputRadioYesNo { 
		flex-direction: column;
		align-items: baseline;
	}

	.inputRadioButton { margin-right: 0 }

	.destinationRow {
		justify-content: flex-start;
		align-items: baseline;
	}

	.destinationCheckbox {
		flex-basis: 20%;
		padding-right: 5px;
	}
}

.creationDossier__trajetLoader {
	text-align: center;
}