.creationDossier__editEnseignant,
.creationDossier__trajet {
    .inputFile__item{
		cursor: pointer;
        &--hidden {
			display: none;

			input {
				height: 0;
				pointer-events: none;
			}
        }
    }
}