.inputFile {
	margin-bottom: 30px;

	.fa-calendar-alt { color: $Valencia }

	label {
		@include fontMedium;
		font-size: size(18);
		margin-bottom: 25px;
	}

	.inputFile__sublabel {
		@include fontBookItalic;
		padding-top: 3px;
		font-size: size(14);
	}

	.inputFile__infos,
	.inputFile__wrapper,
	.inputFile__item {
		&--hidden {
			opacity: 0.00001;
			width: 0;
			height: 0;
			margin: 0;

			input {
				height: 0;
				pointer-events: none;
			}
		}

		i {
			color: $Valencia;
			font-size: 20px;
		}
	}

	.inputFile__item {
		@include vertical-flex;
		justify-content: space-between;
		margin-top: 10px;
		width: 100%;

		&--hidden {
			opacity: 0.00001;
			width: 0;
			height: 0;
			margin: 0;
		}

		span {
			@include fontBookItalic;
			font-size: size(14);
		}
	}

	
	.inputFile__buttons,
	.inputFile__warning {
		&--hidden {
			opacity: 0.00001;
			width: 0;
			height: 0;
			margin: 0;
		}
	}

	.inputFile__infos {
		span {
			@include fontBook;
			font-size: size(16);
			color: $Mine_Shaft;
		}

		svg {
			padding: 5px;
			font-size: size(32);
			background-color: $Valencia;
			color: $White;
			height: 12px;
			width: 12px;
			border-radius: 50%;
			text-align: center;
			line-height: 15px;
			margin-top: 2px;
			margin-left: 13px;
			cursor: pointer;
		}

		span:last-child {
			@include fontBookItalic;
			font-size: size(14);
			color: rgba(51, 51, 51, 0.8);
			margin-top: 3px;
			display: block;
		}
	}

	.inputFile__infos--file i {
		margin-left: 15px;
	}
}

.inputFile__infos--file {
	display: flex;

	i {
		cursor: pointer;
	}
}

.inputFile__infos--fileName {
	cursor: pointer;
}

.inputFile__row {
	@include flex-row;
	margin-top: 10px;
}

.inputFile__calendar {
	@include flex-content-spaceBetween;
	align-items: center;
	border-bottom: 1px solid $Mine_Shaft;
	position: relative;
	margin-right: size(30);
	padding-bottom: 5px;
	flex-basis: 45%;

	time,
	span {
		@include fontBookItalic;
		@include input-reset;
		font-size: size(16);
		border: none;
		color: $Mine_Shaft;
		margin-left: 10px;
	}

	svg {
		color: $Valencia;
		cursor: pointer;
	}

	.reset {
		background: none;
		margin: 0 10px 0 auto;
		padding: 0;
		color: inherit;
	}

	&--basic {
		padding: 0;
		margin: 0;
		height: 38px;
		min-width: 200px;

		.react-calendar { max-width: 300px; }
	}

	.react-calendar {
		@include fontMedium;
		position: absolute;
		top: 35px;
		z-index: 20;

		.react-calendar__tile {
			color: $Mine_Shaft;
		}

		.react-calendar__tile--now {
			background: transparent;
			border-radius: 2px;
			border: 1px solid $Valencia;
		}

		.react-calendar__tile--active {
			border-radius: 2px;
			background: $Valencia;
			color: $White;
		}

		.react-calendar__month-view__days__day--weekend {
			color: $Valencia;

			&.react-calendar__tile--active {
				color: $White;
			}
		}
	}
}

.inputFile__buttons,
.inputFile__singleButton {
	label {
		@include btn-reset;
		font-size: size(14);
		background-color: $Valencia;
		padding: 5px;
		text-align: center;
		border-radius: 3px;
		color: $White;
		margin-bottom: 0;
		z-index: 1;
		width: 100%;
		line-height: 17px;
	}

	input[type="file"] {
		opacity: 0.00001;
		position: absolute;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		cursor: pointer;
		text-indent: -999px; //make the cursor pointer on all the button works
	}
}

.inputFile__buttons {
	@include vertical-flex;
	flex-basis: 45%;
	position: relative;
	cursor: pointer;
}

.inputFile__singleButton {
	display: flex;
    position: relative;
    cursor: pointer;
    width: 40px;
	margin: 0 5px;

	label {
		@include center-flex;
		font-size: size(16);
	}
}

// To override the actual react-calendar active class
.inputFile .inputFile__item .inputFile__calendar .react-calendar .react-calendar__tile--active {
	background: $Valencia;
	border-radius: 2px;
	color: $White;
}

.react-calendar__navigation button {
	color: $Mine_Shaft;
}

.inputFile__item--modifier {
	width: 100%;

	.inputFile__item {
		flex-wrap: wrap;
		margin-top: 10px;
	}

	.inputFile__buttons {
		flex-basis: 45%;
	}

	.piecesObligatoires__item {
		flex-basis: 45%;
		margin-top: 9px;
	}
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
	.inputFile {
		margin-bottom: size(40);

		.inputFile__item {
			flex-direction: column;
			align-items: flex-start;

			span {
				margin-left: 0;
			}

			.inputFile__calendar {
				width: 100%;
				margin-bottom: size(15);
			}

			.inputFile__buttons {
				width: 100%;

				label {
					width: 100%;
				}
			}
		}
	}

	.inputFile__item--modifier {

		.inputFile__calendar {
			max-width: unset;
		}

		.inputFile__buttons {
			max-width: unset;
			margin: size(20) 0 size(30);
		}

		.piecesObligatoires__item {
			max-width: unset;
			width: 100%;
			margin-left: unset;
		}
	}
}


/** inputFileSingle **/
.inputFileSingle {
	.inputFile__item {
		margin: 5px 0;
		padding-bottom: 5px;
		line-height: 1;
	}

	.inputFile__buttons {
		width: 200px;
		margin-top: 5px;
	}

	.fa-trash-alt {
		padding-left: 5px;
		color: $Valencia;
		cursor: pointer;
	}
}

.inputFile__calendarManual {
	&Wrapper {
		margin-top: 20px;
	}

	i {
		color: $Valencia;

		&.fa-calendar-alt {
			position: absolute;
			right: 26px;
			top: 22px;
		}
	}

	.react-date-picker {
		display: flex;
	}

	.react-date-picker__wrapper {
		border:none;
		border-bottom: 1px solid $Mine-Shaft;
	}

	.react-date-picker input {
		padding: 0;
		box-sizing: initial;
	}

	.react-date-picker__inputGroup {
		padding-left: 15px;
	}

	.react-calendar {
		@include fontMedium;
		position: absolute;
		top: 35px;
		z-index: 20;

		.react-calendar__tile {
			color: $Mine_Shaft;
		}

		.react-calendar__tile--now {
			background: transparent;
			border-radius: 2px;
			border: 1px solid $Valencia;
		}

		.react-calendar__tile--active {
			border-radius: 2px;
			background: $Valencia;
			color: $White;
		}

		.react-calendar__month-view__days__day--weekend {
			color: $Valencia;

			&.react-calendar__tile--active {
				color: $White;
			}
		}
	}
}