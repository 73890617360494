header {
	@include flex-row-contentBetween;
	height: 65px;

	.structuresHeader__leftPart {
		@include vertical-flex;
		margin-right: size(50);
	}

	.structuresHeader__rightPart {
		@include flex-row-contentEnd;
		flex-grow: 1;
		border-bottom: 1px solid $Gallery;

		a {
			color: inherit;
		}

		.fa-search {
			display: none;
		}

		.structures__mailbox {
			position: relative;

			a {
				padding: 0 size(20);
			}

			&--hasNotif:after {
				content: '';
				height: 10px;
				width: 10px;
				background-color: red;
				border-radius: 50%;
				position: absolute;
				top: 10px;
				right: 10px;
			}
		}
	}

	.messaging {
		&>span {
			flex: 1;
		}
	}

	.structuresHeader__empty-space {
		flex: 1
	}
}

.searchBar,
.structures__mailbox,
.structuresAccount,
.structures__lang {
	@include flex-row-itemCenter;

	a, div, p { 
		cursor: pointer
	}

	&:before {
		content: "";
		height: 80px;
		width: 1px;
		background-color: $Gallery;
	}
}


.structuresAccount {
	p {
		padding: 0 size(20);
		line-height: unset;

	}
}

.structures__lang {
	.portal__lang {
		padding-bottom: unset;
		padding: 0 size(20);
	}
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
	.searchBar,
	.structures__mailbox,
	.structuresAccount {
		display: none;
	}

	.structures__lang {
		&:before {
			display: none;
		}

		.portal__lang {
			position: initial;
		}

		span {
			background-color: unset;
			color: $Black;

			&:after {
				border-color: $Black transparent transparent transparent;
			}
		}
	}

	.messaging {
		&>span {
			display: none;
		}
	}

	.menuBurger__nav {
		position: relative;
		top: unset;
		right: unset;

		&--open {
			position: fixed;
			top: 20px;
			right: 20px;
		}

		span {
			background-color: $Black;
		}
	}

	header {
		.structuresHeader__rightPart {
			border: none;
			align-items: center;
			flex-grow: unset;

			.fa-search {
				display: block;
				font-size: size(21);
				margin-right: size(20);
			}
		}

		.structuresHeader__rightPart--isOpen {
			overflow: hidden;

			.structures__mailbox,
			.structuresAccount {
				display: block;
			}
		}
	}
}