// ::ng-deep .css-1pahdxg-control:hover {
// 	background-color: red;
// }

.inputSelect {
	max-width: 240px;

	&:focus {
		outline: none;
	}

	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		box-shadow: unset;
		border-radius: unset;
		margin: 0 0 size(15);
		border: 1px solid $Mine-Shaft;
		z-index: 30;
	}

	// Le sélécteur ">" cible l'enfant direct dont la classe contient "-control"
	> div[class*="-control"] {
		border-radius: unset;
		box-shadow: unset;
		border: none;
		border-bottom: 1px solid $Mine-Shaft;
		min-height: unset;
		margin-top: -2px;
		max-height: size(28);

		&:hover {
			border-color: unset;
		}

		// Toutes les classes contenant "-ValueContainer" et "css-"
		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			padding: size(2) 0;
			overflow: hidden;
			position: unset;
			height: 20px;

			&:focus {
				outline: none;
			}

			// Toutes les classes contenant "-placeholder"
			div[class*="-placeholder"] {
				@include fontBookItalic;
				font-size: size(14);
				color: $Dove_Gray;
				margin-left: 0;
				overflow: hidden;
			}

			// Toutes les classes contenant "-singleValue"
			div[class*="-singleValue"] {
				@include fontMedium;
			}
		}

		// Toutes les classes contenant "-IndicatorsContainer"
		div[class*="-IndicatorsContainer"],
		div:nth-child(2) {
			span {
				display: none;
			}

			// Toutes les classes contenant "-indicatorContainer"
			div[class*="-indicatorContainer"] {
				color: $Mine-Shaft;
				right: 0;
				position: absolute;
				padding: 0;
			}

			&:focus {
				outline: none;
			}
		}
	}
}

.inputSelect--readonly {
	div[class*="-IndicatorsContainer"] {
		display: none;
	}
}

.inputSelect__option--disabled {
	opacity: 0.3;
}

.inputSelect--top {
	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		position: absolute !important;
		top: auto !important;
		bottom: calc(100% - 1px) !important;
	}
}

.inputSelect--right {
	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		position: absolute !important;
		right: 0;
	}
}

.inputSelect--large {
	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		width: 300px;
	}
}

.inputSelect--fullWidth {
	max-width: 100%;

	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		width: 100%;
	}
}

.inputSelect--light {
	div[class*="-menu"] {
		border: none;
		box-shadow: 2.5px 5.4px 44.4px 6.6px rgba(51, 51, 51, 0.1);
		color: $Gray;
	}

	div[class*="-placeholder"] {
		color: $Gray;
	}
}

.inputSelect.inputSelect--multi {
	.creationDossier__row .input > & {
		height: auto;
	}

	> div[class*="-control"] {
		max-height: unset;
		margin-top: 3px;

		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			height: inherit;
		}
	}

	div[class*="-multiValue"] {
		padding: 2px 5px;
		border-radius: 10px;
		background-color: $Mine-Shaft;

		> div {
			color: $White;
		}
	}

	input[class*="-dummyInput"] {
		height: auto;
		margin: 0;
		border: none;
		padding: 0px;
		width: auto;
	}
}

.input__label {
	font-size: size(15);
	color: rgba(51, 51, 51, 0.8);
	@include fontBook;
}

.inputSelect__img {
	height: 25px;
	width: 25px;
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
}

.inputSelect__singleValue-wrapper {
	margin-bottom: 13px;
	margin-left: 10px;
}

// ---------- INPUTSELECT FILTRE ----------
.filtres__inputSelect {
	.inputSelect__option {
		@include flex-content-spaceBetween;
		align-items: center;
		padding: 10px;

		&--red {
			@include fontBook;
			margin: -12px;
			padding: 20px;
			border-radius: 4px;
			color: $White;
			background: $Valencia;
			cursor: pointer;

			.fa-plus {
				padding-right: 15px;
				font-size: size(16);
			}

			span {
				font-size: size(16);
			}
		}

		.fa-share {
			padding-right: 5px;
			color: $Valencia;
			font-size: size(8);

			&.item--active {
				color: $White;
			}
		}

		button {
			@include fontMedium;
			@include btn-reset;
			color: inherit;
		}
	}

	.inputSelect-option__item {
		display: flex;
		align-items: center;
	}

	> div[class*="-control"] {
		border: 1px solid $Valencia;
		
		// Toutes les classes contenant "-ValueContainer" et "css-"
		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			&:focus {
				outline: none;
				background: $Valencia;
			}
		}

		// Toutes les classes contenant "-singleValue"
		div[class*="-singleValue"] {
			@include fontBold;
			color: $Valencia;
		}

		// Toutes les classes contenant "-IndicatorsContainer"
		div[class*="-IndicatorsContainer"],
		div:nth-child(2) {
			span {
				display: none;
			}

			// Toutes les classes contenant "-indicatorContainer"
			div[class*="-indicatorContainer"] {
				svg {
					color: $Valencia;
				}
			}

			&:focus {
				outline: none;
			}
		}
	}
}

// ---------- INPUTSELECT FILTRE ITEM => Filtres situés dans le composant Filtres ----------
.filtreItem__inputSelect {
	&:focus {
		outline: none;
	}

	@include fontBook;
	height: 28px;
	font-size: size(14);

	> div[class*="-control"] {
		display: flex;
		align-items: flex-start;
		border: none;
		box-shadow: none;
		outline: none;
		color: rgba(51, 51, 51, 0.3);
		cursor: pointer;

		&:hover {
			border-color: unset;
		}

		// Toutes les classes contenant "-ValueContainer" et "css-"
		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			@include horizontal-flex;
			height: 28px;
			padding: 0;

			&:focus {
				outline: none;
			}

			// Toutes les classes contenant "-singleValue"
			div[class*="-singleValue"] {
				color: $Mine_Shaft_With_Opacity_Plus;
			}
		}

		// Toutes les classes contenant "-IndicatorsContainer"
		div[class*="-IndicatorsContainer"],
		div:nth-child(2) {
			display: none;
		}
	}
}

.filtreItem__inputSelect--selected {
	> div[class*="-control"] {
		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			div[class*="-singleValue"] {
				color: $Black;
			}
		}
	}
}

// ---------- INPUTSELECT PAGINATION ----------
.pagination__inputSelect {
	&:focus {
		outline: none;
	}

	> div[class*="-control"] {
		border: none;
		box-shadow: none;
		outline: none;
		color: rgba(51, 51, 51, 0.3);
		cursor: pointer;

		&:hover {
			border-color: unset;
		}

		// Toutes les classes contenant "-IndicatorsContainer"
		div[class*="-IndicatorsContainer"],
		div:nth-child(2) {
			span {
				display: none;
			}

			// Toutes les classes contenant "-indicatorContainer"
			div[class*="-indicatorContainer"] {
				color: $Mine-Shaft;
				right: 0;
				position: absolute;
				padding: 0;
			}

			&:focus {
				outline: none;
			}
		}
	}
}

// annulé le margin top: 20 des inputs par defaut
.inputSelect--margin {
	margin-top: -20px;
}

//input select FO
.inputSelectFO {
	&:focus {
		outline: none;
	}

	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		box-shadow: unset;
		// background-color: unset;
		border-radius: unset;
		margin: 0 0 size(15);
		border: 1px solid $Mine-Shaft;
		z-index: 30;

		div[class*="-MenuList"] {
			// background-color: $White;
		}

		div[class*="-option"] {
			// background-color: $White;
			color: $Mine-Shaft;
		}
	}

	// Le sélécteur ">" cible l'enfant direct dont la classe contient "-control"
	> div[class*="-control"] {
		border-radius: unset;
		box-shadow: unset;
		border: none;
		border-bottom: 1px solid $Mine-Shaft;
		min-height: unset;
		margin: size(5) 0;
		max-height: size(28);

		&:hover {
			border-color: unset;
		}

		// Toutes les classes contenant "-ValueContainer" et "css-"
		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			padding: size(2) 0;
			overflow: hidden;
			position: unset;
			max-height: 28px;

			&:focus {
				outline: none;
			}

			// Toutes les classes contenant "-placeholder"
			div[class*="-placeholder"] {
				@include fontBookItalic;
				font-size: size(14);
				color: $Dove_Gray;
				margin-left: 0;
				overflow: hidden;
			}

			// Toutes les classes contenant "-singleValue"
			div[class*="-singleValue"] {
				@include fontBook;
			}
		}

		// Toutes les classes contenant "-IndicatorsContainer"
		div[class*="-IndicatorsContainer"],
		div:nth-child(2) {
			span {
				display: none;
			}

			// Toutes les classes contenant "-indicatorContainer"
			div[class*="-indicatorContainer"] {
				color: $Mine-Shaft;
				padding: 0 size(8);
			}

			&:focus {
				outline: none;
			}
		}
	}
}

//input select FO ContractsCachets
.inputSelectContractsCachets {
	&:focus {
		outline: none;
	}

	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		box-shadow: unset;
		// background-color: unset;
		border-radius: unset;
		margin: 0 0 size(15);
		border: 1px solid $Mine-Shaft;
		z-index: 30;

		div[class*="-MenuList"] {
			// background-color: $White;
		}

		div[class*="-option"] {
			// background-color: $White;
			color: $Mine-Shaft;
		}
	}

	// Le sélécteur ">" cible l'enfant direct dont la classe contient "-control"
	> div[class*="-control"] {
		border-radius: unset;
		box-shadow: unset;
		border: none;
		border-bottom: 1px solid $Mine-Shaft;
		min-height: unset;
		margin: size(5) 0;
		max-height: size(28);

		&:hover {
			border-color: unset;
		}

		// Toutes les classes contenant "-ValueContainer" et "css-"
		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			padding: size(2) 0;
			overflow: visible;
			position: unset;
			max-height: 28px;

			&:focus {
				outline: none;
			}

			/deep/ .css-xb97g8 {
				display: none;
			}

			// Toutes les classes contenant "-placeholder"
			div[class*="-placeholder"] {
				@include fontBookItalic;
				font-size: size(14);
				color: $Dove_Gray;
				margin-left: 0;
				overflow: hidden;
			}

			// Toutes les classes contenant "-singleValue"
			div[class*="-singleValue"] {
				@include fontBook;
			}
		}

		// Toutes les classes contenant "-IndicatorsContainer"
		div[class*="-IndicatorsContainer"],
		div:nth-child(2) {
			span {
				display: none;
			}

			// Toutes les classes contenant "-indicatorContainer"
			div[class*="-indicatorContainer"] {
				color: $Mine-Shaft;
				padding: 0 size(8);
			}

			&:focus {
				outline: none;
			}
		}
	}
}

.inputSelectPage {
	&:focus {
		outline: none;
	}

	// Toutes les classes contenant "-menu"
	div[class*="-menu"] {
		box-shadow: unset;
		// background-color: unset;
		border-radius: unset;
		margin: 0 0 size(15);
		border: 1px solid $Mine-Shaft;
		border-top: unset;
		z-index: 30;

		div[class*="-MenuList"] {
			// background-color: $White;
		}

		div[class*="-option"] {
			// background-color: $White;
			color: $Mine-Shaft;
		}
	}

	// Le sélécteur ">" cible l'enfant direct dont la classe contient "-control"
	> div[class*="-control"] {
		border-radius: unset;
		box-shadow: unset;
		border: none;

		width: 120px;
		min-height: unset;
		margin: 0;
		max-height: size(28);

		&:hover {
			border-color: unset;
		}

		// Toutes les classes contenant "-ValueContainer" et "css-"
		div[class*="-ValueContainer"],
		> div[class*="css-"] {
			padding: size(2) 0;
			overflow: hidden;
			position: unset;
			max-height: 28px;

			&:focus {
				outline: none;
			}

			// Toutes les classes contenant "-placeholder"
			div[class*="-placeholder"] {
				@include fontBookItalic;
				font-size: size(14);
				color: $Dove_Gray;
				margin-left: 0;
				overflow: hidden;
			}

			// Toutes les classes contenant "-singleValue"
			div[class*="-singleValue"] {
				@include fontBook;
				color: #d93943;
				font-size: 18px;
				font-weight: bold;
			}
		}

		// Toutes les classes contenant "-IndicatorsContainer"
		div[class*="-IndicatorsContainer"],
		div:nth-child(2) {
			span {
				display: none;
			}

			// Toutes les classes contenant "-indicatorContainer"
			div[class*="-indicatorContainer"] {
				font-weight: bold;
				color: #d93943;
				padding: 0 size(8);
			}

			&:focus {
				outline: none;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.inputSelectFO {
		> div[class*="-control"] {
			max-width: unset;
		}
	}
}
