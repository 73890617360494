// ---------- SHARED HELPERS ----------
@import "adel-shared/dist/sass/helpers/_colorsFO";
@import "adel-shared/dist/sass/helpers/_functions";
@import "adel-shared/dist/sass/helpers/_mixins";
// Pas de shared pour les fonts
@import "./helpers/_fonts";
@import "adel-shared/dist/sass/helpers/_vars";


// ---------- FONT ----------
@import "./font/_titles";
@import "./font/_paragraph";


// ---------- SHARED ----------
@import "adel-shared/dist/sass/form/_buttons";
@import "adel-shared/dist/sass/_input";
@import "adel-shared/dist/sass/_inputRadioButton";
@import "adel-shared/dist/sass/_inputFile";
@import "adel-shared/dist/sass/_inputSelect";
@import "adel-shared/dist/sass/_collapsibleSection";
@import "adel-shared/dist/sass/_inputReadOnly";
@import "adel-shared/dist/sass/_toggle";
@import "adel-shared/dist/sass/_table";
@import "adel-shared/dist/sass/_artisteModal";
@import "adel-shared/dist/sass/_pagination";
@import "adel-shared/dist/sass/_messagerie";
@import "adel-shared/dist/sass/_textarea";
@import "adel-shared/dist/sass/_jodit";
@import "adel-shared/dist/sass/_calendar";
@import "adel-shared/dist/sass/_totalAmountsTable";
@import "adel-shared/dist/sass/_detailsDossier";


// ---------- COMMON FORM ----------
@import "./common/form/_checkboxround";
@import "./common/form/_searchbar";
@import "./common/form/_textarea";
@import "./common/form/_errorMessage";


// ---------- COMMON MODALS ----------
@import "./common/_modals";


// ---------- COMPONENTS MODAL ----------
@import "./common/modal/_modal";



// ---------- ELEMENTS ----------
@import "./elements/scrollbar";
@import "./elements/toast";
@import "./elements/messageWarning";


// ---------- COMPONENTS ----------
@import "./structure/components/_footer";
@import "./structure/components/_filters";
@import "./structure/components/_tooltip";
@import "./structure/components/_chip";
@import "./structure/components/_navigation";


// ---------- PAGE ----------
@import "./structure/page/_contact";
@import "./structure/page/_validation";
@import "./structure/page/_counter";
@import "./structure/page/_password";
@import "./structure/page/_creationDossier";
@import "./structure/page/_detailsDossier";
@import "./structure/page/_monCompte";


// ---------- SIGNUP ----------
@import "./structure/page/signup/_login";
@import "./structure/page/signup/_signuplist";
@import "./structure/page/signup/_signupend";
@import "./structure/page/signup/_menuBurger";
@import "./structure/page/signup/_infosJuridiques";
@import "./structure/page/signup/_infosBancaires";
@import "./structure/page/signup/_infosRelatives";
@import "./structure/page/signup/_piecesObligatoires";


// ---------- CONTAINER ----------
@import "./structure/containers/_structures.scss";


// ---------- STRUCTURE ----------
@import "./structure/front_office/_structuresheader";
@import "./structure/front_office/_structuresfooter";
@import "./structure/front_office/_structuresfoldercard";
@import "./structure/front_office/_structuresfoldercardstatus";
@import "./structure/front_office/_structuresfoldercardheaderactions";
@import "./structure/front_office/_structuresfoldercardfooteractions";
@import "./structure/front_office/_structuresfolderaddbutton";
@import "./structure/front_office/_structuresfolderinfos";
@import "./structure/front_office/_structuresUnauthorized";



// ---------- STRUCTURE MESSAGERIE ----------
@import "./structure/messagerie/_mailbox";
@import "./structure/messagerie/_mailboxmessage";
@import "./structure/messagerie/_mailboxmessagefolderinfos";
@import "./structure/messagerie/_mailboxsearchbar";
@import "./structure/messagerie/_mailboxtabs";


// ---------- STRUCTURE CREATION DOSSIER ----------
@import "./structure/creation_dossier/_validationStructure2";
@import "./structure/creation_dossier/_criteres";
@import "./structure/creation_dossier/_questionInputs";
@import "./structure/creation_dossier/_stats";
@import "./structure/creation_dossier/_enseignants";
@import "./structure/creation_dossier/_rules";
@import "./structure/creation_dossier/_lieuxDates";
@import "./structure/creation_dossier/_budget";
@import "./structure/creation_dossier/_description";
@import "./structure/creation_dossier/_infosProjet";
@import "./structure/creation_dossier/_salarie";
@import "./structure/creation_dossier/_trajet";
@import "./structure/creation_dossier/_contratCachets";



// ---------- VERSEMENTS ----------
@import "./structure/versements/versementsListe";
@import "./structure/versements/versementsArtistes";
@import "./structure/versements/versementsEnseignants";
@import "./structure/versements/versementsPiecesJointes";



.App {
	background: url(../assets/img/spedidam_background.png) no-repeat;
	background-size: cover;
	min-height: 100vh;
	position: relative;

	>div {
		height: 100%;
	}
}