.mailboxtab {

    a {
        @include link-reset;
        @include fontBook;
        color: $Mine_Shaft;

        &:first-child {
            
            &:after {
                content: ' |';
                margin: 0 15px;;
            }
        } 
    }
}