.mailboxmessagefolderinfos {
    @include flex-row-itemCenter;
    margin-top: size(40);

    .mailboxfolderinfos__item {
        max-width: calc(100% - 42px);
    }

    &.active {
        border-right: 4px $Valencia solid;
    }

    &.new {
        background-color: #f8fbfe;
    }

    p {
		@include fontBook;
        @include text-ellipsis;
        font-size: size(14);
        color: $Mine_Shaft_With_Opacity;
    }

    img {
        margin-right: size(12);
        width: 30px;
        height: 30px;
    }

    h3 {
        @include fontBold;
        @include text-ellipsis;
        font-size: size(20);
    }
}