.piecesObligatoires__item {
	margin-bottom: 15px;

	.input {
		margin-top: 0;
	}
	.inputSelectFO {
		margin-bottom: 25px;
	}

	.inputSelectFO
		> div[class*="-control"] {
			max-width: 45%;
	}
}

.piecesObligatoires__loader {
	margin: 100px 20px;
	text-align: center;
}

@media screen and (max-width: 768px) {
	.piecesObligatoires__item {
		flex-direction: column;

		.inputSelectFO>div[class*="-control"] {
			max-width: unset;
		}
	}
}

.piecesObligatoires {
	.inputFile {
		position: relative;
		margin-top: 30px;
	}
}