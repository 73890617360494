.folderCard {
    @include flex-column;
    box-shadow: 0.8px 1.8px 6px 0 rgba(51, 51, 51, 0.15);
    min-height: 210px;

    .folderCard__header {
        @include flex-row-contentBetween;
        padding: 15px 25px;
        padding-right: 15px;
    }

    .structures__foldercard--body {
        flex-grow: 1;
        padding: 15px 25px;
        padding-right: 15px;
        line-height: 24px;

        p {
            @include fontBook;
            font-size: size(14);
            color: $Mine_Shaft_With_Opacity;
        }

        div {
            @include flex-row;
            justify-content: flex-start;
            line-height: 30px;

            img {
                align-self: center;
                margin-right: 12px;
            }

            p {
                @include text-ellipsis;
            }
        }

        h3 {
            @include fontBold;
            font-size: size(20);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

// ---------- RESPONSIVE ----------
@media (max-width: 640px) {
    .folderCard {
        width: 100%;
    }
}