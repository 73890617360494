#structure {
	@include center-flex;
	@include fontBook;
	width: 100%;
	height: auto;
	min-height: 100%;
	max-height: fit-content;
	padding: 30px;
	box-sizing: border-box;

	.modal {
		@include flex-column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		min-height: calc(95vh - 30px);
		background-color: $White;
		padding: size(20) size(40);

		.folders {
			margin: size(40) size(30) 20px;
			flex: 1 1;
			position: relative;
			max-height: 76%;
			@include flex-column;

			.folders__title {
				@include flex-row-contentBetween;
				align-items: center;
				padding: 30px 0;

				h2 {
					margin: 0;
				}
			}

			.folders__title--actions {
				@include flex-row-contentBetween;
				width: 300px;
				align-items: baseline;
			}

			.folders__title--icons {
				width: 55px;
				@include flex-row-contentBetween;

				i {
					color: $Valencia;
					cursor: pointer;
					font-size: size(20);
				}

				.icon--active {
					opacity: 1;
				}

				.icon--inactive {
					opacity: 0.5;
				}
			}

			.folders__grid--layout {
				overflow-x: hidden;
				padding: 10px 20px 10px 2px;
				box-sizing: border-box;
			}

			.folders__loader {
				@include flex-row-contentCenter;
				align-items: center;
				width: 100%;
				height: 100%;

				&:first-child {
					margin-top: 100px;
				}
			}

			.folders__grid {
				display: grid;
				grid-template-columns: repeat(auto-fill, 400px);
				grid-gap: 1rem;
				justify-content: space-between;
			}

			.noFolder {
				position: absolute;
				width: 100%;
				top: 30%;
				height: 70%;
			}

			.noFolder__item {
				position: relative;
				text-align: center;
				height: 100%;
				width: 100%;

				img {
					max-height: 65%;
					max-width: 100%;
				}

				p {
					margin: 30px 0 15px;
					font-size: size(19);
					color: $Mine_Shaft;
				}
			}

			.folders--empty {
				@include flex-column;
				justify-content: center;
				text-align: center;

				img {
					align-self: center;
					margin-bottom: 30px;
				}
			}
		}
	}
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 920px) {
	#structure {
		.modal {
			.folders {
				.folders__grid {
					justify-content: center;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#structure {
		padding: size(10);
		box-sizing: border-box;

		.modal {
			padding: size(5) size(30);

			.folders {
				margin: 0;
			}
		}

		.folders__addButton {
			display: none;
		}

		.structure__footer {
			display: none;
		}
	}
}

@media screen and (max-width: 640px) {
	#structure {
		.modal {
			.folders {
				.folders__title--actions {
					justify-content: end;
					width: fit-content;

					.folders__addButton {
						span {
							display: none;
						}

						i {
							position: relative;
							width: 36px;
							height: 36px;
							border-radius: 50%;
							color: $White;
							background-color: $Valencia;
							margin: 0;

							&:before {
								@include center-absolute;
								font-size: 20px;
							}
						}
					}

					.folders__title--icons {
						display: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	#structure {
		.modal {
			.folders {
				.folders__grid {
					grid-template-columns: repeat(auto-fill, 280px);
					margin-top: size(30);
				}
			}
		}
	}
}

@media screen and (max-width: 367px) {
	#structure {
		.modal {
			padding: size(5) size(10);
		}
	}
}
