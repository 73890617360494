﻿.creationDossier__salarieAristesButtons {
	.icon-button {
		font-size: 12px;

		&--first {
			margin-left: 15px;
		}
	}
}

.creationDossier__salarieAristesLoader {
	text-align: center;
}

.creationDossier__validationArtisteModal {
	ul li span {
		text-transform: lowercase;
	}

	ul ul {
		padding-left: 20px;
	}

	&.modal__item {
		ul ul li {
			list-style-type: circle;
		}
	}
}
