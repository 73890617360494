.folderCard__headerActions {
    @include flex-row;
    justify-content: space-evenly;
    font-size: size(14);
    line-height: 20px;
    color: $Valencia;

    .message, span {
        margin-right: 14px;
        @include fontBold;

    }

    &>div {
        margin-left: 5px;
        &:hover {
            cursor: pointer;
        }
    }
    
    i {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: $White;
        background-color: $Valencia;
        font-size: size(14);
        text-align: center;
        line-height: 20px;
    }
}