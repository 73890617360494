.versementsArtistes {
	section {
		margin: 25px 0;
	}

	.creationDossier__addElementButton {
		display: inline-block;
		font-size: 18px;

		i { padding-right: 10px }
	}

	.textarea__item textarea {
		max-width: 100%;
		min-height: 200px;
	}

	.inputReadOnly {
		margin-top: 0;
		padding-bottom: 0;

		label {
			color: $Mine_Shaft;
			font-size: 18px;
		}
		p {
			margin: 13px;
			line-height: 1;
			color: $Mine_Shaft_With_Opacity;
			font-size: 14px;
		}
	}
}

.versementsArtistes__headerButton {
	@include flex-row-contentBetween;
	align-items: center;
}

.versementsArtistes__table {
	overflow-x: auto;

	.inputFileSingle,
	.inputFile__buttons {
		margin: 0;
	}

	.inputFile__buttons {
		width: auto;
	}

	td {
		&:nth-child(9) {
			text-align: center;
			width: 20px;
			max-width: 20px;

			.actionButton {
				padding: 0 5px;
			}
		}
	}

	.actionButton {
		font-size: 15px;
		cursor: pointer;
	}

	.menuTable {
		position: relative;

		&__content {
			z-index: 2;
			position: absolute;
			top: 25px;
			right: 0;
			width: 125px;
			padding: 10px;
			box-shadow: 0 0 8px 0 rgba(184, 184, 184, 0.5);
			background: $White;
	
			li {
				cursor: pointer;
				&:hover {
					@include fontBold;
					background: $Alabaster;
				}
	
				a, div {
					display: block;
					padding: 10px;
				}
			}
	
			i {
				width: 20px;
				margin-right: 10px;
			}
			.fa-trash-alt {
				color: initial;
				padding-left: 0;
			}
		}
	}
}


.versementsArtistes__depenseInput {
	.input {
		position: relative;
	}

	.input__errorMessage {
		position: absolute;
	}
}

.versementsArtistes__warning {
	@include fontBold;
	@include flex-row;
	align-items: center;
	margin: 20px 0 40px;
	padding: 15px 20px;
	background-color: rgba(217, 57, 67, 0.1);
	color: $Valencia;
	font-size: 14px;

	i { margin-right: 15px }

	.disclaimer-content { padding: 0 }
	.disclaimer-content i { padding: 0 }
}

.versementTab__artistesModal {
	overflow-y: auto;
}