﻿.questionField {
	position: relative;
	width: 100%;
}

.questionField__title {
	@include fontBook;
	margin-bottom: 10px;
	font-size: 18px;

	a {
		color: $Mine_Shaft;
		text-decoration: underline;
	}
}

.questionField__fileName {
	word-break: break-all;
}

.choiceListGroup {
	display: block;
	width: 100%;

	.input { 
		margin-top: 0;
		flex-basis: 100%;
		max-width: 100%;
	}

	label {
		margin-right: 5px;
	}

	.input__label a {
		color: $Mine_Shaft;
		text-decoration: underline;
	}

	.choiceListOther { 
		width: 100%;
	}
}

.dateInputGroup {
	border-bottom: none;
	padding: 0;
	margin: 10px 0;

	&__item {
		display: inline;

	}
}

.blockingQuestion {
	color: #FF0000;
	font-weight: bold;
	margin: 10px 0;
}

/** Pour les AnswerType.Array **/
.creationDossier__item.questionFieldAnswerArrayItem {
	position: relative;
	margin-bottom: 10px;
	padding: 0;
}



/** Special modifier **/
.creationDossier__item--autoFlexBetween {
	.creationDossier__row .choiceListGroup {
		width: auto;
	}
}

.questionField input:disabled {
	&+label { cursor: default; }
}