.structure__unauthorized {
	header .structuresHeader__rightPart { justify-content: flex-end }

	.structure__unauthorizedContent { text-align: center }

	.unauthorizedImg { 
		height: 300px;
		margin-bottom: 25px;
	}
}
