.folderInfos{
    flex-grow: 1;
    padding: 15px 25px;
    padding-right: 15px;
    line-height: 24px;

    p {
        @include fontBook;
        font-size: size(14);
        color: $Mine_Shaft_With_Opacity;
    }

    h3 {
        padding: 6px 0px;
        height: 46px;
    }

    div {
        @include flex-row;
        @include vertical-flex;
        justify-content: flex-start;
        line-height: 30px;

        p { @include text-ellipsis; }
    }
    
    h3 {
        @include fontBold;
        font-size: size(18);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.folders__table, .folderInfos {
	img {
		align-self: center;
		margin-right: 8px;
		height: 25px;
	}
}

.folders__table {
	.folderCard__headerActions { justify-content: flex-start }
	.structures__foldercardStatus { display: inline-block }
}

.folders__tableStatut, .folders__tableOptions {
	@include flex-row;
	align-items: center;
}

.folders__tableOptions {
	.folderCard__footerButton { margin-left: 5px }
}
