.menuBurger {
	display: none;

	.mobile__menu {
		position: fixed;
		z-index: 49;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: $Valencia;
		padding: 45px;
	}
}

.mobile__menu {
	color: $White;

	footer {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20px;
	}
}

.mobile__menuItem {
	padding: 25px;
}

.menuBurger__nav {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 20px;
	height: 20px;
	z-index: 50;

	input {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		opacity: 0.0001;
		z-index: 50;
		cursor: pointer;

        &:checked~span:first-child {
            background-color: green;
            transform: translateY(7px) translateX(0) rotate(45deg);
        }
    }

	span {
		position: absolute;
		background: $White;
		border: none;
		height: 2px;
		width: 20px;
		z-index: 20;
		transform-origin: 4px 0px;
		transition: all .35s ease;
	}

	.middle {
		top: 7px;
	}

	.bottom {
		top: 14px;
	}
}

.menuBurger__nav input:checked~.top {
	transform: translateY(5px) translateX(2px) rotate(45deg);
}

.menuBurger__nav input:checked~.middle {
	opacity: 0;
}

.menuBurger__nav input:checked~.bottom {
	transform: translateY(-1px) translateX(0) rotate(-45deg);
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
	.menuBurger {
		display: block;
	}
}
