/** JODIT OVERRIDE */

.jodit_fullsize { position: fixed !important }

.jodit-toolbar-editor-collection_mode_vertical .jodit-toolbar-button__button  {
	color: $Mine_Shaft;
}

// Fix rapide d'un scroll apparu sur un texte copié collé ayant important son propre style css
.ReactModalPortal .pageVitrine__wrapper .modal__scroll .jodit-container .jodit-workplace .jodit-wysiwyg {
	>div:nth-child(4) {
		>strong {
			>span {
				>div {
					margin-left: unset!important;
					margin-right: unset!important;
				}
			}
		}
	}
}

.jodit-tooltip { padding: 0 !important }

.jodit-popup__content .jodit-tabs__buttons {
	.jodit-ui-button.jodit-ui-button_Background,
	.jodit-ui-button.jodit-ui-button_Text {
		&, &:hover { background: #333; }
	}
}

.jodit-toolbar-button__text { color: #4c4c4c; }