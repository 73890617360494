$font-weight-normal:          500 !default;
$font-weight-semi-bold:       600 !default;
$font-weight-bold:            $font-weight-semi-bold !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$letter-spacing-base:         .02em !default;

@font-face {
    font-family: 'CircularStd-Bold';
    src: url('../../assets/fonts/CircularStd-Bold.eot');
    src: url('../../assets/fonts/CircularStd-Bold.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-Bold.woff') format('woff'),
    url('../../assets/fonts/CircularStd-Bold.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-Bold.svg') format('svg');
}

@mixin fontBold {
    font-family: 'CircularStd-Bold';
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd-BoldItalic';
    src: url('../../assets/fonts/CircularStd-BoldItalic.eot');
    src: url('../../assets/fonts/CircularStd-BoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-BoldItalic.woff') format('woff'),
    url('../../assets/fonts/CircularStd-BoldItalic.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-BoldItalic.svg') format('svg');
}

@mixin fontBoldItalic {
    font-family: 'CircularStd-BoldItalic';
    font-weight: $font-weight-normal;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd-Medium';
    src: url('../../assets/fonts/CircularStd-Medium.eot');
    src: url('../../assets/fonts/CircularStd-Medium.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-Medium.woff') format('woff'),
    url('../../assets/fonts/CircularStd-Medium.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-Medium.svg') format('svg');
}

@mixin fontMedium {
    font-family: 'CircularStd-Medium';
    font-weight: $font-weight-normal;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd-MediumItalic';
    src: url('../../assets/fonts/CircularStd-MediumItalic.eot');
    src: url('../../assets/fonts/CircularStd-MediumItalic.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-MediumItalic.woff') format('woff'),
    url('../../assets/fonts/CircularStd-MediumItalic.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-MediumItalic.svg') format('svg');
}

@mixin fontMediumItalic {
    font-family: 'CircularStd-MediumItalic';
    font-weight: $font-weight-normal;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd-Book';
    src: url('../../assets/fonts/CircularStd-Book.eot');
    src: url('../../assets/fonts/CircularStd-Book.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-Book.woff') format('woff'),
    url('../../assets/fonts/CircularStd-Book.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-Book.svg') format('svg');
}

@mixin fontBook {
    font-family: 'CircularStd-Book';
    font-weight: $font-weight-normal;    
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd-BookItalic';
    src: url('../../assets/fonts/CircularStd-BookItalic.eot');
    src: url('../../assets/fonts/CircularStd-BookItalic.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-BookItalic.woff') format('woff'),
    url('../../assets/fonts/CircularStd-BookItalic.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-BookItalic.svg') format('svg');
}
@mixin fontBookItalic {
    font-family: 'CircularStd-BookItalic';
    font-weight: $font-weight-normal;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd-Black';
    src: url('../../assets/fonts/CircularStd-Black.eot');
    src: url('../../assets/fonts/CircularStd-Black.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-Black.woff') format('woff'),
    url('../../assets/fonts/CircularStd-Black.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-Black.svg') format('svg');
}
@mixin fontBlack {
    font-family: 'CircularStd-Black';
    font-weight: $font-weight-normal;    
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd-BlackItalic';
    src: url('../../assets/fonts/CircularStd-BlackItalic.eot');
    src: url('../../assets/fonts/CircularStd-BlackItalic.woff2') format('woff2'),
    url('../../assets/fonts/CircularStd-BlackItalic.woff') format('woff'),
    url('../../assets/fonts/CircularStd-BlackItalic.ttf') format('truetype'),
    url('../../assets/fonts/CircularStd-BlackItalic.svg') format('svg');
}
@mixin fontBlackItalic {
    font-family: 'CircularStd-BlackItalic';
    font-weight: $font-weight-normal;
    font-style: italic;
}
