.react-date-picker {
    z-index: 20;
}

.inputFile__calendar {
    .react-calendar--left-top,
    .react-calendar--right-top {
        top: auto;
        bottom: 50px;
    }
    
    .react-calendar--right-top,
    .react-calendar--right-bottom {
        right: 0;
    }
}