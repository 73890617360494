// ---------- BUTTON ----------

button {
	@include fontMedium;
	@include btn-reset;
	font-size: size(16);
	background-color: $Valencia;
	padding: size(10);
	border-radius: 3px;
	color: $White;
	opacity: 1;

	&:disabled {
		opacity: 0.6;
	}
}

.button__submit {
	@include btn-reset;
	background-color: $Valencia;
	padding: size(10);
	border-radius: 3px;
	color: $White;
}

// ---------- PORTAL VALIDATION BUTTON ----------
.modal__CTA {
	@include flex-content-spaceBetween;
	align-items: center;
	align-self: stretch;
	margin-top: size(40);

	.modal__CTA--button {
		font-size: size(12);
		color: $Dove_Gray;
		border: none;
		background-color: transparent;
		padding: 0;
	}

	input {
		@include btn-reset;
		background-color: $Valencia;
		width: 100px;
		height: 40px;
		border-radius: 3px;
		color: $White;
		cursor: pointer;
	}
}

.passwordValidation {
	.modal__CTA {
		justify-content: flex-end;
	}
}

.passwordValidated {
	.modal__CTA {
		justify-content: center;
	}
}

// ---------- MODAL VALIDATION BUTTON ----------
.signupfooter__CTA {
	@include flex-center-spaceBetween;
	margin-top: auto;

	span {
		@include fontBookItalic;
		font-size: size(12);
		color: $Dove_Gray;
	}

	.button__submit {
		background-color: $Sea_Pink;
		pointer-events: none;
	}

	.button__submit--validation {
		background-color: $Valencia;
		pointer-events: all;
	}
}

// ---------- MODAL INPUT WITH VALIDATION BUTTON ----------
.inputValidation {
	margin: 1px 0 30px;

	label {
		@include fontMedium;
		font-size: size(18);
	}

	.inputValidation__item {
		position: relative;
		display: flex;
		height: size(40);
		box-sizing: border-box;

		input {
			@include fontBook;
			display: block;
			margin: 5px 0 0;
			border: none;
			width: 100%;
			font-size: size(16);
			color: rgba(51, 51, 51, .8);
			border-bottom: 1px solid $Mine-Shaft;

			&::placeholder {
				@include fontBookItalic;
				font-size: size(13);
			}

			&:focus {
				outline: none;
			}
		}

		button {
			position: absolute;
			right: 0;
			bottom: 5px;
		}
	}
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
	button {
		font-size: size(16);
		padding: size(5) size(10);
	}

	.modal__CTA {
		flex-direction: column;
		width: 100%;
		margin-top: 45px;

		input,
		button {
			width: 100%;
			margin-top: 15px;
		}
	}

	.signupfooter__CTA {
		flex-direction: column;
		justify-content: flex-end;
		position: static;
		width: 100%;
		flex: 1;

		span {
			margin-bottom: 20px;
		}

		button {
			width: 100%;
			padding: size(10);
		}
	}
}

// ---------- OTHER BUTTONS ----------
.icon-button {
	display: inline-flex;
    justify-content: center;
    align-items: center;
	height: 10px;
	width: 10px;
	font-size: size(12);
	margin: 0 3px;
	padding: 5px;
	color: $White;
	background: $Valencia;
	border-radius: 50%;
	cursor: pointer;

	.fa, .far {
		color: $White;
	}
}
