.inputReadOnly {
	label {
		// @include fontBook;
		// font-size: size(15);
		// color: $Mine_Shaft_With_Opacity;
		color: #000000;

		font-family: Inter;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		letter-spacing: 0em;
		text-align: left;

	}

	&--light {
		label {
			color:rgba(0, 0, 0, 0.8);
		}
	
		p {
			margin-top: 4px;
			white-space: break-spaces;
		}
	}
}
