// ---------- LISTE ----------
@mixin list-reset {
    list-style: none;
    padding-left: 0;
}

// ---------- BOUTON ----------
@mixin btn-reset {
    background: none;
    border: none;
	padding: 0;

	&:focus {
		outline: 0;
    }

    &:hover {
        cursor: pointer;
    }
}

// ---------- LIEN ----------
@mixin link-reset {
    text-decoration: none;
    color: $Black;
}

// ---------- FLEXBOX ----------

            /* FLEX WRAP **/
@mixin flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

            /* FLEX CONTENT **/
@mixin flex-content-spaceAround {
    display: flex;
    justify-content: space-around;
}

@mixin flex-content-spaceBetween {
    display: flex;
    justify-content: space-between;
}

@mixin flex-content-end {
    display: flex;
    justify-content: flex-end;
}


            /* FLEX ALIGN **/
@mixin flex-align-spaceBetween {
    display: flex;
    align-content: space-between;
}

@mixin flex-item-center {
    display: flex;
    align-items: center;
}

@mixin flex-item-end {
    display: flex;
    align-items: flex-end;
}

            /* FLEX ROW **/
@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-row-contentBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@mixin flex-row-spaceAround {
    display: flex;
    flex-direction: row;
    justify-content: space-around
}

@mixin flex-row-contentCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@mixin flex-row-contentEnd {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

            /* FLEX ROW ALIGN **/
@mixin flex-row-itemCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin flex-row-itemEnd {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

            /* FLEX COLUMN **/
@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-column-contentBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@mixin flex-column-contentEnd {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@mixin flex-column-contentCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin flex-column-itemCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-column-itemLeft {
    display: flex;
    flex-direction: column;
    align-items: left;
}

            /* FLEX ALIGN **/
@mixin flex-align-start {
    display: flex;
    align-items: flex-start;
}

@mixin flex-align-center {
    display: flex;
    align-items: center;
}

@mixin flex-align-end {
    display: flex;
    align-items: flex-end;
}

            /* FLEX CONTENT ALIGN **/
@mixin flex-start-contentBetween {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@mixin flex-end-contentBetween {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

// ---------- CENTRAGE HORIZONTAL ----------
@mixin horizontal-relative {
    margin: 0 auto;
}

// Parent en position relative
@mixin horizontal-absolute {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

// Appliquer sur le parent
@mixin horizontal-flex {
    display: flex;
    justify-content: center;
}

// ---------- CENTRAGE VERTICAL ----------
// Appliquer sur le parent
@mixin vertical-flex {
    display: flex;
    align-items: center;
}

// Parent en position relative
@mixin vertical-absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// ---------- CENTRAGE ----------
// Appliquer sur le parent
@mixin center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-center-spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// Parent en position relative
@mixin center-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// ---------- LISTES ----------
// listes verticales
@mixin vertical-list {
    list-style-type: none;
    margin: 5px 0;
}
// listes horizontales
@mixin horizontal-list {
    list-style-type: none;
    display: inline;
}

// ---------- INPUT ----------
@mixin input-reset {
    border: none;

	&:focus {
		outline: 0;
    }

    &:hover {
        cursor: pointer;
    }
}

// ---------- ELLIPSIS ----------
@mixin text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// ---------- HRIGHT ----------
@mixin fullHeight-page {
	height: 100%;
	min-height: calc(100vh -  #{$header-height});
}
