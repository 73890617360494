.detailsDossier {
    @include flex-column;
	flex-grow: 1;
	padding: 30px;
    position: relative;

    h3 {
		color: $Valencia;
        font-size: 20px;
        margin-bottom: 15px;
    }

    &__infoDossier {
        color: $Mine_Shaft_With_Opacity;
        margin-bottom: 40px;
    }

    &__item {
        margin-bottom: 25px;
	    padding: 0 25px;
    }

    &__row {
        display: flex;
        margin: 20px 0;

        .inputReadOnly {
            flex-basis: 250px;
        }
    }

    &__footer {
        margin: 30px 0 0;
        display: flex;
        align-items: center;
        height: 40px;
    }

    &__btn + &__btn {
        margin-left: 20px;
    }

    .creationDossier__item {
        padding-left: 0;
    }

    .table {
        margin-bottom: 30px;
    }

    .detailsTab__section {
        padding: 30px 0;
        position: relative;
        border-bottom: 3px solid $border-color;
    
        &:nth-last-child(1) {
            border: none;
        }
    
        &Sub {
            margin-bottom: 50px;
        }
    }

    .detailsTab__row {
        @include flex-row-contentBetween;
    
        &--flex-end {
            @include flex-row-contentBetween;
            align-items: flex-end;
    
            .input label {
                font-size: size(14);
            }
        }
    }

    .detailsTab__column {
        @include flex-column;
        width: 33.333%;
        white-space: normal;
        word-break:break-all;
        &--fullWith {
            width: 100%;
        }
    
        .input {
            margin-right: 10px;
        }
    }

    .detailsTab__content {
        position: relative;
        padding-top: size(20);
        padding-right: 10px;
        white-space: normal;
        word-break:break-all;
        margin-top: 10px;
    }

    .detailsTab__iconsList {
        display: flex;
    
        .total {
            margin-right: 60px;
    
            span:first-child {
                font-size: 48px;
            }
        }
    
        .icons {
            color: rgba(51, 51, 51, .7);
            @include flex-wrap;
            flex: 1 0 auto;
            align-content: space-between;
    
            > div {
                flex: 1 0 25%;
                max-width: 25%;
            }
    
            i { margin-right: 10px; }
        }
    }

    // details table
    .detailsTab__table {
        color: rgba(51, 51, 51, .7);
        width: 100%;

        + .detailsTab__table {
            border-top: 3px solid $border-color;
            margin-top: 30px;
            padding-top: 30px;
        }

        thead {
            th:first-child {
                padding-bottom: 20px;
                color: #333;
            }
        }

        th {
            font-weight: normal;
            text-align: left;

            &[scope="colgroup"] {
                color: #333;
                font-weight: bold;
                padding: 15px 0 10px 20px;
            }
        }

        td {
            font-size: size(16);
            padding: 2px;
        }

        .budgetCol { width: 45%; }
    }

    @media print {
        position: relative;

        .detailsTab__section {
            position: relative;
            page-break-inside: avoid;
        }
    }
}

.detailsTab__amounts {
    @include flex-wrap;
    margin: 30px 0;
    background: #d93943;
    color: #fff;

    > div {
        border-top: 1px solid rgba(255, 255, 255, .3);
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        padding: 25px 10px;

        + div {
            border-left: 1px solid rgba(255, 255, 255, .3);
        }
    }

    .amount__number {
        font-size: 24px;
    }

    .amount__info {
        color: rgba(255, 255, 255, .6);
    }
}