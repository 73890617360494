// ---------- COMMUN BO / FO ----------
.messageSeparateur {
	@include flex-row-contentBetween;
	align-items: center;
	margin: size(40) 0;

	span {
		flex: 1;
		height: 1px;
		background-color: $Mine_Shaft;
	}

	p {
		margin: 0 size(20);
		color: $Mine_Shaft;
		font-size: size(14);
	}
}

.messagerie__display {
	@include flex-row;
	flex: 1;

	.customModal__card {
		max-width: 500px;

		.input {
			flex-basis: 100%;

			.inputSelect {
				margin-top: 10px;
				max-width: unset;
			}

			.inputSelect > div[class*="-control"] {
				border-radius: unset;
				box-shadow: unset;
				border: none;
				border-bottom: 1px solid #333333;
				max-height: unset;

				> div[class*="css-"] {
					height: auto;
				}
			}
		}
	}

	.messageList {
		max-width: 260px;
		border-right: 2px solid rgba(51,51,51,.12);
		margin-right: 30px;
		margin-top: 30px;
		flex: 1;
		box-sizing: border-box;
	}

	.detailsTab__section {
		flex: 1;
	}

	.cardList {
		position: relative;
		@include vertical-flex;
		flex-direction: row;
		//margin-bottom: size(20); //BO ONLY
		cursor: pointer;
		padding: 15px 0 15px 20px;

		.cardList__item {
			width: calc(100% - 30px);
		}

		.text__ellipsis {
			@include text-ellipsis;
		}

		p {
			font-size: size(14);
			color: $Gray;
		}

		h4 {
			font-size: size(20);
			color: $Mine_Shaft;
			margin: size(2) 0;
		}

		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
		}

		img {
			margin-right: 10px;
			max-width: 30px;
		}

		&.nonLu:after {
			content: '';
			height: 10px;
			width: 10px;
			background-color: red;
			border-radius: 50%;
			position: absolute;
			top: 20px;
			right: 10px;
		}
	}

	.active {
		background-color: #F8FBFE;
	}

	.cardList__header {
		@include flex-row-contentBetween;

		h4 {
			font-size: size(14);
			color: $Dove_Gray;
		}
	}

	// SUREMENT A REFAIRE, INTEGRATION VIA SCREEN SUR TACHE (PAS DE ZEPLIN)
	.cardList__item {
		@include flex-column;

		button {
			font-size: size(12);
			border-radius: 30px;
			padding: 5px;
			width: max-content;
			align-self: end;

			&.isActive {
				background-color: #fae0cd;
				color: #ef975d;
			}

			&.isNotActive {
				background-color: #cef2d0;
				color: #3fcb48;
			}
		}
	}

	// Entete
	.messageHeader {
		@include flex-column;
		min-height: 100px;

		&__item {
			@include flex-row-contentBetween;
			align-items: center;
			flex: 1;
		}

		&__logo {
			margin: 0 10px 0 0;
			
			img { max-width: 30px; }
		}

		&__left {
			display: flex;
		}

		&__button {
			@include flex-row-contentBetween;
			align-items: flex-end;

			.inputSelect > div[class*="-control"] {
				width: 130px;
				margin-top: 10px;
			}

			button{
				margin-left: 25px;
			}
		}
	}
}
