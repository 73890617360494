.signupEnd {
    text-align: center;

    .modal__title {
        margin-bottom: 0;

        &:not(:first-child){
            margin-top: 0;
        }
    }

    .signupEnd__img {
        margin: 50px auto;

        img {
            height: 138px;
        }
    }

    .signupEnd__content{     
        max-width: 80%;
        margin: auto;
        margin-bottom: 50px;
        
        h3 {
            @include fontMedium;
            font-size: size(20);
        }

        p {
            @include fontBook;
            font-size: size(16);
            &:nth-child(2){
                margin: 30px auto;
            }
        }
    }

    input {
        @include btn-reset;
        background-color: $Valencia;
        height: 40px;
        padding: 5px 25px;
        border-radius: 3px;
        color: $White;
    }
}