.criteres {
	position: relative;

	h2 {
		@include fontMedium;
		font-size: 24px;
		color: $Mine_Shaft;
	}

	h4 {
		@include fontMedium;
		font-size: 18px;
		margin-bottom: 20px;
		color: $Mine_Shaft;
	}

	p {
		@include fontBook;
		color: rgba(51, 51, 51, 0.7);
    }
    
    .criteres__action {
        @include btn-reset;
        @include flex-row;

        i {
            color: $Valencia;
            margin-right: 5px;
        }

        p {
            color: $Mine_Shaft;
            @include fontBold;
            margin-bottom: 20px;
        }
    }

	.criteres__item {
		width: 100%;
		min-height: 400px;
		max-height: 450px;
		box-shadow: 0.8px 1.8px 6px 0 rgba(51, 51, 51, 0.15);
		padding: 30px;
		box-sizing: border-box;
		overflow-y: auto;

		.creationDossier__checkbox {
			margin: 45px 0;
			color: $Mine_Shaft;
			font-size: 12px;
			@include fontBold;
		}
	}
}