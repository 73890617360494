.creationDossier {
	@include flex-column;
	flex-grow: 1;
	padding: 30px;

	.input {
		margin-top: 20px;
	}

	.input__dataList {
		top: 45px;
	}

	label.label--withPadding {
		display: block;
		padding-bottom: 15px;
	}

	.inputFile__item--modifier {
		position: relative;
	}

	.input__errorMessage {
		position: relative;
	}
}

.creationDossier__wrapper {
	@include flex-row-contentBetween;
	padding: 30px 45px 0;
}



/** Menu **/
.creationDossier__menu {
	flex-shrink: 0;
   	width: 300px;

	li {
		font-size: size(18);
		color: $Mine_Shaft;
		opacity: .5;

		&:not(:first-child) {
			&:before {
				content: '';
				display: block;
				height: 20px;
				margin: 5px 0 5px 14px;
				border-left: 1px dashed $Valencia;
			}
		}

		&.active {
			@include fontBold;
			opacity: 1;
			color: $Valencia;
			cursor: pointer;

			.creationDossier-menu__check i {
				display: block
			}
		}
	}
}

.creationDossier-menu__check {
	position: relative;
	height: 20px;
	width: 20px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
	border-radius: 50%;
	background-color: $Valencia;
	border: 5px solid $Sea_Pink;

	i {
		display: none;
		@include center-absolute;
		font-size: size(18);
		color: $White;
	}
}



/** Content **/
.creationDossier__item {
	margin-bottom: 25px;
	padding: 0 25px;

	&--fullwidth {
		margin: 30px 0;
	}

	.creationDossier__row.row--full {
		flex-basis: 100%;
	}

	.creationDossier__itemRow {
		position: relative;
	}

	.piecesObligatoires__item  {
		:not(.inputFile__buttons) >label {
			@include fontMedium;
			color: $Mine_Shaft;
			font-size: size(18);
		}

		.inputSelectFO {
			width: 45%;

			>div[class*="-control"] {
				max-width: 100%;
			}
		}
	}

	.inputFile__buttons {
		width: 200px;
	}
}

.creationDossier__content {
	flex: 1 1;
	min-width: 0;
	margin: 6px 0;
	max-width: 1100px;

	h3 {
		font-size: 20px;
		color: $Valencia;

		&:not(.collapsibleSection__title) {
			margin-bottom: 15px
		}
	}

	h3.title--dark {
		color: $Mine_Shaft;
	}

	h4.title--red {
		@include fontBold;
		margin-bottom: 20px;
		font-size: 18px;
		color: $Valencia;
	}

	h2 {
		font-size: size(24);
		color: $Mine_Shaft;

		&:not(.collapsibleSection__title) {
			margin-bottom: 15px
		}
	}

	.table {
		margin-top: 15px;
		margin-bottom: 0;

		.actionButton i {
			margin-right: 5px;
		}
	}

	.inputFile__calendar {
		max-width: 100%;
		padding-top: 2px;
		padding-bottom: 5px;
		position: relative;

		span {
			margin-left: 0;
			line-height: 1;
		}

		time {
			@include fontBook;
			margin-left: 0;
			padding-right: 5px;
			padding-left: 15px;
			box-sizing: border-box;
			color: $Gray;
			font-size: 1em;
		}

		.fa-calendar-alt {
			color: $Valencia
		}
	}
}

.listItems {
	margin-top: 10px;
	color: $Mine_Shaft_With_Opacity;
}

.listItemsContent {
	min-height: 25px;
	padding-top: 10px;

	.fa-trash-alt {
		padding-left: 5px;
		color: $Valencia;
		cursor: pointer;
	}
}

.creationDossier__row {
	@include center-flex;

	&.row--flexStart {
		align-items: flex-start;
	}

	&.row--flexEnd {
		align-items: flex-end;
	}

	&.row--flexDirectionRow {
		flex-direction: row;
	}

	.creationDossier__rowItem {
		display: flex;

		&--flex1 {
			flex: 1;
		}

		&--flex3 {
			flex: 3;
		}

		.input {
			padding-right: 25px;
		}
	}

	&>div {
		position: relative;
		padding: 0 25px 20px 0;
		width: 100%;
	}

	.input {
		flex-basis: 100%;

		.inputSelect {
			height: 28px;
			margin: 5px 0;
			@include flex-align-end;
			max-width: 100%;

			>div[class*="-control"] {
				width: 100%;

				>div[class*="-ValueContainer"] {
					padding-left: 15px;

					>div[class*="-singleValue"] {

						>div {
							color: $Gray;
							@include fontBook;
						}
					}
				}
			}
		}
	}

	.inputContent {
		margin: 12px 0;
		padding-left: 10px;
		height: 5px;
		line-height: 1;
		color: $Mine_Shaft_With_Opacity;
	}

	:not(.inputFile__buttons) > label {
		color: $Mine_Shaft;
	}

	.inputReadOnly  {
		margin-top: 20px;

		label {
			color: $Mine_Shaft_With_Opacity;
		}
	}

	input {
		padding-left: 15px;
		box-sizing: border-box;
		color: $Gray;
	}
}

.creationDossier__checkbox {
	margin-bottom: 15px;

	input {
		margin-right: 10px;
	}

	label, .InputRadioYesNo {
		color: $Mine_Shaft;
		font-size: 16px;
	}
}

.creationDossier__singleRightButton {
	@include flex-row-contentEnd;
}

.creationDossier__block {
	padding-bottom: 35px;

	.creationDossier__item {
		margin-bottom: 0;
	}
}

.creationDossier__littleBlock {
	padding-bottom: 10px;

	h3 button {
		color: $Valencia;
		background: none;
	}

	.input__errorMessage {
		position: absolute;
		top: 53px;
	}
}

.creationDossier__addElement {
	color: $Valencia;
	font-size: 16px;
	@include flex-row-itemCenter;
	cursor: pointer;

	i, button, &Button {
		background-color: $White;
		color: $Valencia;
		font-size: 24px;
		padding: 10px 5px;
		cursor: pointer;
	}

	.creationDossier__addElementButton {
		line-height: 1;
	}

	&.elementButton--absolute {
		position: absolute;
		right: -60px;
		bottom: 10px;
	}
}

// titre en gris en petit en dessous des h4 rouges
.creationDossier__subtitle {
	margin-top: -10px;
	color: $Mine_Shaft_With_Opacity;
	font-size: size(14);

	.subtitle--red {
		color: $Valencia;
	}
}


/** Header **/
.creationDossier__header {
	@include flex-center-spaceBetween;
	align-items: center;

	&--alt {
		position: relative;

		.navigationFil {
			color: $Mine_Shaft_With_Opacity;
		}
	}
}



/** Footer **/
.creationDossier__footer {
	@include flex-row-contentBetween;
	align-items: center;
	margin: 25px 20px 0 0;

	span {
		font-style: italic;
		font-size: size(14);
	}

	.button__submit--draft {
		margin: 0 20px 0 auto;
	}
}



/** Table **/
.creationDossier__table-buttons {
	display: flex
}



/** Special modifier **/
.creationDossier__item--autoFlexBetween {
	@include flex-item-end;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0;

	.creationDossier__row {
		display: block;
		flex-basis: 50%;
	}

	.input {
		width: auto;
	}
}

.creationDossier__item--singleRows {
	.creationDossier__row {
		padding-bottom: 20px;
	}
}

.creationDossier__item--singleRows,
.creationDossier__item--singleRows-noPadding {
	.creationDossier__row {
		.input { flex-basis: 100% }
	}
}


.creationDossier__infoDossier {
	margin-left: 20px;
	margin-bottom: 20px;
	color: $Mine_Shaft_With_Opacity;
}
