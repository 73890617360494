#structure {
    footer {
        border-top: 1px solid $Gallery;
        margin-bottom: 0;

        li {
            color:$Mine_Shaft;
            display: flex;
            align-items: center;
        }
    }
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
    #structure {

        .menuBurger__nav input:checked~.top,
        .menuBurger__nav input:checked~.bottom {
            background-color: $White;
        }

        footer {
            border: none;

            li {
                font-size: 19px;
                line-height: 22px;
                opacity: 0.7;
                color: $White;
            }
        }
    }
}