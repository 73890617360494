.monCompte {
    @include flex-column;
    flex-grow: 1;
    padding: 30px;

	.defaultCheckbox {
		margin-left: size(25);
		margin-bottom: size(25);
	
		span {
			color: $Gray;
		}
	}

    .account__form {
        flex: 1;
        max-width: 1100px;
    }

	.piecesJointes {
		.piecesJointes__item {
			padding: 15px 0;
			@include fontBook;

			.piecesJointes__name {
				font-size: size(18);
				font-weight: bold;
				padding: 5px 0 10px 0;
			}

			.piecesJointes__infos {
				font-size: size(16);
				.extraInfos{
					font-style: italic;
					color: rgba(51, 51, 51, 0.7);
				}
			}

			.piecesJointes__fileName {
				padding-bottom: 8px;

				.fa-file{
					color: $Valencia;
					padding-right: 10px;

					&.disabled { color: $Mine_Shaft }
				}
				.piecesJointes__fileNameText{
					color:black;
				}
			}
		}

		a {
			position: relative;
			height: auto
		}

	}

	.input__errorMessage { position: static; }
}

.monCompte__edit {
	&Checkbox {
		margin-bottom: 30px;
	}

	&Footer {
		@include flex-row-contentBetween;
		button:first-child {
			background-color: $White;
			border: 1px solid $Valencia;
			color: $Valencia;
		}
	}

	.input__label { 
		display: block;
		margin-bottom: 10px;
	}
}

.monCompte__header {
	@include flex-row-itemCenter;
	margin-bottom: size(35);

	h3 {
		margin-right: 15px;
		font-size: size(24);
		color: $Mine_Shaft;
	}

	button {
		@include btn-reset;
	}

	i {
		color: $Valencia;
		font-size: size(20);
		cursor: pointer;
	}
}

.monCompte__footer {
	@include flex-row-contentBetween;
	margin-top: size(50);

	button {
		padding: size(13) size(20);
		font-size: size(16);

		&:first-child {
			@include btn-reset;
			color: $Valencia;
			border: 1px solid $Valencia;
			border-radius: 3px;
			padding: size(13) size(20);
		}
	}
}

.monCompte__items {
	padding: 0 45px;
	@include flex-row-contentBetween;
}

.monCompte__formRow {
	display: flex;

    .input {
		flex: 1 1;
        padding-right: 25px;
    }
}

.monCompte__menu {
	flex-basis: 350px;
	margin-right: 80px;

	.account__menuItem {
		@include flex-item-center;
		margin: 20px 20px 20px 0;
	}

	li {
		position: relative;
		@include flex-row-itemCenter;
	}

	.account__menuHeader {
		margin-right: 15px;
		@include flex-row-itemCenter;

		span {
			display: block;
			background-color: $Valencia;
			width: 16px;
			height: 2px;
			@include vertical-absolute;

			&:nth-child(2) {
				position: relative;
				transform: rotate(90deg);
				transition: transform 0.3s ease;
			}
		}
	}

	.account__menuIcon {
		margin-right: 15px;
		position: relative;
	}

	a {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
	}
}

.monCompte .monCompte__menu a.active~.account__menuHeader span:nth-child(2) {
    transform: rotate(180deg);
}

.monCompte .monCompte__menu a.active~.account__menuHeader p,
.monCompte .monCompte__menu a.active~i {
    color: $Valencia;
}

.monCompte .account__form .input {
	margin-bottom: 35px;
	margin-top: 0;

    label {
        @include fontMedium;
		color: $Mine_Shaft;
	}

	input {
        box-sizing: border-box;
		padding: 0 10px;

		&:not(.email):not([name="email"]) {
			text-transform: capitalize;
		}

		&::placeholder {
			@include fontBook;
			font-size: size(16);
			color: rgba(51, 51, 51, 0.7);
		}
	}

    >div[class*="-container"] {
		height: 28px;
		border-bottom: 1px solid $Mine_Shaft;

        >div[class*="-control"] {
            border: none;
            border-radius: unset;
			border-bottom: unset;

            span {
                display: none;
            }

            >div[class*="-ValueContainer"] {
                >div[class*="-singleValue"] {
                    font-size: size(16);
                    @include fontBook;
                    color: rgba(51, 51, 51, 0.4);
                }
            }
        }

        >div[class*="-menu"] {
			>div[class*="-MenuList"] {
                >div[class*="-option"] {
                    background-color: $White;
                    color: $Mine_Shaft;

                    &:hover {
                        background-color: rgba(217, 57, 67, .3);
                    }
                }
            }
        }
    }
}

.monCompte .account__form .readOnly__item .input {
	label {
		@include fontBook;
		color: $Gray;
	}

	.itemSingle { 
		padding: 0 10px;
		line-height: 2;
	}
}

.monCompte .account__form .inputFileSingle .inputFile__buttons label { color: $White }

.monCompte .readOnly__item {
	display: flex;

	.input {
		padding-right: 25px;
		flex: 1 1;
	}
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
    .monCompte {
        padding: 15px;

        h2 {
            margin-bottom: 35px;
        }
	}
	
	.monCompte__header {
		display: none;
	}

	.monCompte__items {
		display: initial;
		padding: 0;
	}

	.monCompte__menu {
		margin: 0;

		a {
			width: calc(100% - 40px);
		}

		.account__menuItem {
			li button {
				@include btn-reset;
				display: none;

				i {
					color: $Valencia;
				}
			}
		}

		.account__menuHeader {
			margin-right: auto;

			span {
				width: 14px;
			}

			p {
				font-style: bold;
				font-size: size(18);
			}
		}

		.account__menuItem {
			display: initial;

			li {
				margin-left: 0;
				justify-content: flex-end;
			}

			&>div,
			&>section {
				margin-top: 30px;
			}
		}
	}

	.monCompte__formRow {
		flex-direction: column;
	}

	.readOnly .readOnly__item {
		flex-direction: column;
	}
}


.monCompte .monCompte__menu a.active~button {
	display: block;
}

.defaultContact {
	margin-bottom: 15px;
}

.donneesBancairesReadOnly {
	.readOnly__item {
		@include flex-row-contentBetween;
		box-sizing: border-box;
	}

	.input {
		flex-basis: 45%;

		input {
			border: none;
			@include fontMedium;
			color: $Mine_Shaft;
			margin: 0;
		}
	}
}