.searchBar {
    flex-grow: 1;

    &:before {
        background-color: transparent !important;
    }

    i {
        font-size: size(20);
    }

    input {
        @include fontBold;
        @include input-reset;
        margin-left: size(10);
        font-size: size(25);
        color: $Mine_Shaft;
    }
}