﻿.creationDossier__description {
	.inputValidation {
		position: relative;
		margin-bottom: 20px;
	}

	.inputValidation .inputValidation__item {
		input {
			margin-right: 25px;
		}

		button {
			right: 25px;
		}
	}

	.input {
		margin-top: 0;
	}

	.creationDossier__item.creationDossier__descriptionItem {
		.creationDossier__row {
			margin-bottom: 30px;

			& > div {
				padding-bottom: 0;
			}
		}
	}
	
	.creationDossier__addElementButton {
		i {
			vertical-align: middle;
		}
	}

	.creationDossier__addElementButtonLabel {
		padding-left: 10px;
		font-size: size(14);
	}

	.creationDossier__addElement.elementButton--absolute {
		width: auto;
	}

	.errorMessage--outBlock {
		margin: 25px;
	}
}

.inputValidation {
	span {
		@include fontMedium;
		font-size: size(15);
	}
}
