.collapsibleSection__button {
	@include btn-reset;
	width: 100%;
}

.collapsibleSection__title {
	@include flex-center-spaceBetween;
	font-size: size(18);
	color: $Valencia;
	font-family: 'CircularStd-Bold';
	cursor: pointer;
}

.collapsibleSection__content {
	height: 0;
	overflow: hidden;
	margin-bottom: 0;

	&--show {
		height: 100%;
	}
}
