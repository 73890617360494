p, span {
    @include fontBook;
    line-height: size(24);
    font-size: size(16);
}

.textLowerCase {
	text-transform: lowercase;
}

// ---------- ADRESSE DE CORRESPONDANCE ----------
.validation {
    text-align: center;

    p {
        margin-top: size(20);
    }
}