.table {
	width: 100%;
	border-spacing: unset;
	margin-bottom: 50px;

	a {
		color: $Black;
		text-decoration: underline;
	}

	.fa-plus-circle {
		cursor: pointer;
	}
}

.table__header {
	.table__row:first-child {
		display: none;
	}
}

.table__footer {
	.table__row:last-child {
		display: none;
	}
}


.table__header,
.table__footer {

	&--color { background-color: #fafafa }

		.table__row {
			height: 60px;
			background-color: $Valencia;
			color: #FFFFFF;
		}

	.table__label {
		@include fontBook;
		padding: $table-cell-padding;

		&--font {
			font-size: size(15);
			color: #FFFFFF;
		}
	}

	.table__text {
		display: flex;
		align-items: center;
		text-align: left;

		i {
			width: 14px;
			margin-right: 15px;
		}
	}

	.table__select-all {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100px;

		label { margin: 0; }

		input[type="checkbox"] {
			position: static;
		}
	}
}

.table__body {

	.table__row {
		height: 60px;
		color: $Mine_Shaft;
		font-size: size(15);
		background-color: rgba(91, 120, 140, 0);
		border: 1px solid rgba(91, 120, 140, 0.5);
		&--highlight {
			td {
				background: #f5f5f5;
			}
		}
	}

	.table__row2 {
		height: 60px;
		color: $Mine_Shaft;
		font-size: size(15);
		background-color: rgba(230, 235, 238, 1);
		border: 1px solid rgba(91, 120, 140, 0.5);
		&--highlight {
			td {
				background: #f5f5f5;
			}
		}
	}

	.table__text:first-child{
		font-weight: bold;
	}
	.table__text {
		padding: $table-cell-padding;
		text-align: left;
		color: $White;
		border-bottom: 1px solid #f5f5f5;

		color: rgba(0, 0, 0, 0.70);
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: normal;
		line-height: normal;
		a {
			text-decoration: none;
			font-weight: bold;
		}

		&--font {
			@include fontBook;
		}

		&--fixWidth {
			width: 400px;
			max-width: 400px;
		}

		&--fixWidthSmall {
			width: 300px;
			max-width: 300px;
		}
	}


	.table__statut {
		border-bottom: 1px solid #f5f5f5;

		&Item {
			background-color: $Gallery;
			display: inline-flex;
			margin: 10px;
			padding: 3px 10px;
			border-radius: 11px;
			align-items: center;
		}

		@each $status, $color in $status-colors {
			&--#{$status} {
				color: nth($color, 1);
				background-color: nth($color, 2);
			}
		}

		&--fixWidth {
			width: 150px;
			max-width: 150px;
		}
	}

	.table__check {
		display: block;
		width: 50px;
		max-width: 50px;
		text-align: center;
	
		&--valid {
			color: $Malachite;
		}
	
		&--invalid {
			color: $Valencia;
		}
	}
}

.detailsTab__table.detailsTab__tableBudget {
	td:first-child {
		padding-left: 40px;
	}

	th, td {
		&:nth-child(1) {
			width: 55%;
			max-width: 55%;
		}

		&:nth-child(2) {
			width: 15%;
			max-width: 15%;
		}

		&:nth-child(3), &:nth-child(4) {
			width: 15%;
			max-width: 15%;
		}
	}

	.strong {
		color: $Valencia;
		font-weight: bold;
	}
}
