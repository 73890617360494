
.versements {
	h3.title--red {
		@include fontBold;
		margin-bottom: 20px;
		font-size: size(20);
		color: $Valencia;
	}
}

.versementsListe__table {
	.table__body .table__text {
		padding: 0 30px;
	}

	.versements__tableButtons {
		@include flex-row;
		justify-content: flex-end;
	}

	.versements__tableIcon {
		@include flex-row-itemCenter;
		justify-content: center;
        width: 23px;
        height: 23px;
		margin: 0 3px;
		border-radius: 50%;
        color: $White;
        background-color: $Valencia;
		font-size: size(15);
		cursor: pointer;
	}
}

.versementStatut {
	&__content {
		@include flex-row;
		div:first-child { flex: 2 }
		div:last-child { flex: 1.25 }
	}

	&__item {
		padding: 25px 25px 0;
	}

	&__row {
		@include flex-row-contentBetween;
	}

	.inputReadOnly {
		padding-bottom: 30px;

		label { font-size: size(15) }
		p { font-size: size(18) }
	}
}

.versementStatut__header {
	@include flex-row-itemCenter;
	justify-content: center;
	flex-shrink: 0;
	margin: 20px 0 80px;

	li {
		opacity: .3;

		&:not(:first-child) {
			&:before {
				content: '';
				width: 100px;
				height: 1px;
				display: inline-block;
				margin: 5px 10px;
				border-bottom: 1px dashed $Valencia;
			}
		}

		&.active {
			@include fontBold;
			opacity: 1;
			cursor: pointer;

			.versementStatut__headerCheck {
				height:25px;
				width:25px;
			}

			.versementStatut__headerItem label {
				margin-left: -55px;
			}

			.versementStatut__headerCheck i {
				display: block;
			}
		}
	}
}

.versementStatut__headerItem {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	@each $status, $color in $status-colors {
		&.statut--#{$status} {
			color: nth($color, 1);

			.versementStatut__headerCheck {
				border-color: nth($color, 3);
				background-color: nth($color, 1);
			}
		}
	}

	label {
		position: absolute;
		width: 150px;
		margin-left: -60px;
		text-align: center;
		font-size: size(16);
	}
}

.versementStatut__headerCheck {
	position: relative;
	display: block;
    margin: 0 auto 10px;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 5px solid;
	font-size: size(18);
	background-clip: padding-box;

	i {
		display: none;
		@include center-absolute;
		font-size: size(18);
		color: $White;
	}
}

.versementsLoader {
	text-align: center;
}
