.infosRelatives {
	height: calc(100% - 50px);
    @include flex-column;

	.textarea__item {
		flex:1;
		margin-bottom: 0;

		textarea {
			height: 100%;
		}
	}
}
