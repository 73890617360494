.folderCard__footer {
    @include flex-row-contentBetween;
	@include fontMedium;
	align-items: center;
    font-size: size(13);
    background-color: $Selago;
	padding: 10px 25px;
	position: relative;

	&--disabled {
		.folderCard__footerButton--versement {
			opacity: .5;
			cursor: default;

			&:hover {
				&+.folderCard__footerTooltip--versement {
					display: block;
				}
			}
		}
	}

	a {
		color: inherit;
	}

    .alert {
        color: $Valencia;

        i {
            margin-right: 10px;
		}
	}

	.separator {
		margin: 0 10px;
		border-right: 1px solid $Gallery;
		display: inline-block;
		height: 20px;
		vertical-align: middle;
	}

	&RightPart {
		flex-shrink: 0;
	}
}

.folderCard__footerButton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: size(18);
	height: 25px;
	width: 25px;
	margin-left: 5px;
	border-radius: 50%;
	color: $White;
	background-color: $Sea_Green;
	cursor: pointer;

	a { color: $White }
}

.folderCard__footerButton--versement {
	i {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid white;
		border-radius: 50%;
		width: 15px;
		height: 15px;
		font-size: 10px;
		font-weight: bold;
	}
}

.folderCard__footerTooltip {
	display: none;
	position: absolute;
	top: -40px;
	right: 10px;
	padding: 10px 15px;
	background: $White;
	border-radius: 5px;
	box-shadow: 0.4px 0.9px 16.9px 1.1px rgba(152, 150, 150, 0.18);
}

.folderCard__footerButton--convention {
	&:hover {
		&+.folderCard__footerTooltip--convention {
			display: block;
		}
	}
}

.folderCard__footerButton--ca {
	&:hover {
		&+.folderCard__footerTooltip--ca {
			display: block;
		}
	}
}

.folderCard__ButtonTable { position: relative }
