.artisteModal {
	.artisteModal__section { margin-bottom: 50px }
	.modal__content { overflow-y: auto }

	.table { 
		margin-bottom: 0;
		th, td { width: 33% }
	}


	.detailsTab__column {
		@include flex-column;
		width: 33.333%;
		&--fullWith { width: 100%;}
		white-space: normal;
        word-break:break-all;
		.input {
			margin-right: 10px;
		}
	}

	.detailsTab__item {
		margin-top: 25px;
		@include flex-row-contentCenter;
	}
	
	.detailsTab__row {
		@include flex-row-contentBetween;
		&--start { justify-content: flex-start }
		&--flex-end {
			@include flex-row-contentBetween;
			align-items: flex-end;
		}
	}

	.detailsTab__content {
		position: relative;
		padding-top: 15px;
		padding-right: 10px;
		white-space: normal;
        word-break:break-all;
		.input {
			width: 240px;
		}
	
		.table {
			margin-bottom: 30px;
	
			&__list li {
				list-style: inside disc;
			}
		}
	
		label {
			@include fontBook;
			color: $Mine_Shaft_With_Opacity;
		}

		p {
			font-size: size(16);
		}
	}	
}
