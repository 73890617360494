.mailboxMessage {
    @include flex-row-contentBetween;
    align-items: center;

    .mailboxMessage__item {
        @include fontBook;

        h6 {
            font-size: 14px;
            color: $Gray;
        }

        p {
            font-size: 14px;
            @include fontBold;
        }
    }
}

.messagerie {
	.message {
		margin-bottom: 30px;
	
		.messageTopInfosAuthor { 
			@include fontBold;
			margin-right: 3px;
		}
	
		.messageTopInfos { @include flex-row }
	
		.messageContent {
			@include flex-row;
			margin: 10px 0;
		}
	
		.messageTopInfosRight,
		.messageContentRight {
			justify-content: flex-end;
		}
	
		.messageContentText {
			flex-basis: 80%;
			padding: 15px;
			background: $Selago;

			p { background: none !important } // Pour Jodit
		}
	}
	
	.messageSeparateur {
		span {
			background-color: $Gallery;
		}

		p { 
			@include fontBold;
			font-size: size(16);
		}
	}

	.message__files {
		margin-top: 20px;
	}
}
