.inputRadioButton {
	padding: 5px 10px 5px 0;
	input { display: none }
}

.inputRadioButton__label {
	@include vertical-flex;
	cursor: pointer;

	.fa-circle,
	.fa-check-circle {
		font-size: size(20);
		margin-right: 10px;
		line-height: 1;
	}

	.fa-circle { color: $Mine_Shaft }
	.fa-check-circle { color: $Valencia }
}


.InputRadioYesNo {
	display: flex;
	align-items: center;
	margin: 5px 0;

	.inputRadioButton {
		margin-right: 5px;

		input { display: none }
	}
}
