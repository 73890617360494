.mailbox {
    padding: 0 size(50);
    flex: 1;
    @include flex-column;

    & > span {
        margin-top: size(40);
        color: $Mine_Shaft;
    }

    h2 {
        margin-top: size(10);
    }

    & > a {
        margin-top: size(35);
    }

    .messageList {
        min-width: size(320);
        max-width: size(325);
        overflow: auto;
        box-sizing: border-box;
    }

    .mailbox__rightPart {
        @include flex-column-contentBetween;
        flex: 1;

		.messageHeader__item {
			&Title {
				@include flex-row-contentBetween;
				.inputReadOnly p { @include fontBold }
			}
			&SubTitle { color: $Mine_Shaft_With_Opacity }
		}
    }

    .mailbox__rightPartMobile {
        display: none;
    }

    .mailbox__editor {
		margin: 20px 0 0;

        > div[class*="jodit"] {
            width: 100%!important; // possède une propriété "width: auto" en inline que je ne peux modifier autrement
        }
    }

    .mailbox__editor--CTA {
		@include flex-row;
		justify-content: flex-end;
		margin: 20px 0;

        button {
            bottom: size(35);
            margin: 0;
            width: max-content;
        }
	}

	.messagerie {
		margin-top: 20px;
    }
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 1024px) {
    .mailbox {
        padding: 0 size(10);

        .mailbox__leftPart {
            min-width: size(250);
        }

        .mailbox__rightPart {
            margin-left: size(50);
        }
    }
}

@media screen and (max-width: 768px) {
    .mailbox {
        padding: 0;
        position: relative;

        // span {
        //     display: none;
        // }

        // .mailboxsearchbar {
        //     display: none;
        // }

        // .mailbox__rightPart,
        // .mailboxMessage__item,
        // .mailbox__editor {
        //     display: none;
        // }

		.mailbox__item {
			@include flex-column;
		}

		.mailbox__rightPart {
			margin-left: 0;
		}
        .mailbox__leftPart {
            flex: 1;
            max-width: unset;
            min-width: unset;
            max-height: unset;
            box-shadow: unset;
        }

        .mailbox__rightPartMobile {
            @include flex-column;
            width: 100%;
            height: 100%;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            background: $White;

            .messagerie {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                width: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                max-height: calc(100% - 190px);
                height: 100%;
            }
        }

        .mailboxMessage {
            align-items: flex-start;
            flex: 1;
        }

        .mailbox__editorMobile {
            align-self: flex-end;

            .mailbox__editor--CTA {
                @include flex-row;

                button {
                    position: initial;
                    margin: size(10) 0 size(15);
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}