.checkboxRound {
    @include flex-row;

    .checkboxRound__item {
        position: relative;

        label {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 50%;
            cursor: pointer;
            height: 14px;
            left: 0;
            position: absolute;
            top: 0;
            width: 14px;

            &:after {
                border: 1.8px solid $White;
                border-top: none;
                border-right: none;
                content: "";
                height: 3px;
                left: 2px;
                opacity: 0;
                position: absolute;
                top: 3px;
                transform: rotate(-46deg);
                width: 8px;
            }
        }
        
        input[type="checkbox"] {
            visibility: hidden;

            &:checked + label {
                background-color: $Valencia;
                border-color: $Valencia;

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .checkboxRound__label {
        margin-left: 12px;
        line-height: size(14);

        label {
            @include fontMedium;
            font-size: size(18);

            span {
                @include fontBookItalic;
                font-size: size(14);
            }
        }
    }
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
    .checkboxRound__label {

        span {
            display: block;
            margin-top: size(8);
            font-size: size(14);
        }
    }
}