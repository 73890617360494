.totalAmountsTable {
	@include flex-wrap;
	margin: 30px 0;
	background: $Valencia;
	color: #fff;

	> div {
		border-top: 1px solid rgba(255, 255, 255, .3);
		display: flex;
		flex: 1 0 auto;
		justify-content: center;
		padding: 25px 10px;

		+ div {
			border-left: 1px solid rgba(255, 255, 255, .3);
		}
	}

	.totalAmountsTable__value {
		@include fontBold;
		font-size: 30px;
	}

	.totalAmountsTable__label {
		color: rgba(255, 255, 255, .8);
	}
}