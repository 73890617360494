.textarea__item {
	margin-bottom: 30px;
	
	label {
		@include fontMedium;
		font-size: size(18);
	}

	textarea {
		@include fontBook;
		display: block;
		margin: 20px 0;
		padding: size(10);
		border: 1px solid $Mine-Shaft;
		min-height: 300px;
		width: 100%;
		max-width: 612px;
		box-sizing: border-box;
		resize: none;
	}
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
	.textarea__item {
		textarea {
			max-width: 100%;
		}
	}
}