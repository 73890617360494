﻿#signupList {
	background-color: $Valencia;
}

.signupList__button {
	padding: 0;
	@include flex-item-center;
	margin-bottom: 25px;
}

.signupList__navigation {
	ul {
		margin: 0;
		padding: 0;
	}

	li {
		@include vertical-list;
		@include fontMedium;

		span {
			display: inline-block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: rgba(255, 255, 255, 0.7);
			margin-right: 15px;

			&.signupList__navIcon--active {
				opacity: 1;
			}

			&.signupList__navIcon {
				opacity: 0.6;
			}

			span {
				display: inline-block;
				height: 20px;
				width: 20px;
				border-radius: 50%;
				background-color: $White;
				color: $Valencia;
				margin: 5px;
				text-align: center;
				font-size: size(18);
				line-height: 20px;
			}
		}

		p {
			display: inline;
			opacity: 0.6;
			text-align: left;

			&.signupList__nav--active {
				opacity: 1;
				@include fontBold;
				cursor: pointer;
				pointer-events: all;
			}
		}
	}
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
	#signupList {
		display: none;
	}
}
