.structures__foldercardStatus {
	@include fontMedium;
	font-size: size(14);
	border-radius: 10px;
	padding: 4px 10px;

	&--brouillon {
		color: $Valencia;
		background-color: $Valencia_With_Opacity;
	}

	&--soumis {
		color: $Mine_Shaft_With_Opacity;
		background-color: $Gallery;
	}

	&--priseEnCharge,
	&--prisEnCharge {
		color: $Vermilion;
		background-color: $Vermilion_With_Opacity;
	}

	&--programmeEnCommission {
		color: $Bamboo;
		background-color: $Bamboo_With_Opacity;
	}

	&--accepte {
		color: $Fun_Green;
		background-color: $Fun_Green_With_Opacity;
	}

	&--solde {
		color: $Malachite;
		background-color: $Green_With_Opacity;
	}

	&--horsCritere {
		color: $Guardsman_Red;
		background-color: $Guardsman_Red_With_Opacity;
	}

	&--refuse, &--reaffecte {
		color: $Gulf_Blue;
		background-color: $Gulf_Blue_With_Opacity;
	}
}