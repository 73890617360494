.rules__container {
	width: 100%;
	box-shadow: 0.8px 1.8px 6px 0 rgba(51, 51, 51, 0.15);
	background-color: #f8fbfe;
	padding: 20px;
	margin: 30px 0;
	@include flex-row;
	box-sizing: border-box;

	i {
		color: #d93943;
		margin-right: 15px;
	}

	.rules__text {
		color: rgba(51, 51, 51, 0.7);
		@include fontBook;
		font-size: 14px;
		white-space: pre-wrap;
	}
}