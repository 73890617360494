h2 {
    font-size: size(30);
    @include fontMedium;
    margin: size(5) 0 size(15);
    color: $Mine_Shaft;
}

.modal__leftPart {
    h2 {
        color: $White;
    }

    h4 {
        margin-bottom: 20px;
    }
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
    .modal__rightPart {

        h2 {
            font-size: size(26);
            margin-bottom: size(30);
            margin-top: 0;
            text-align: center;
        }
    }
}

// ---------- RESPONSIVE ----------
@media screen and (max-width: 520px) {
    h2 {
        font-size: size(32);
        margin: size(10) 0 size(5);
    }

    .modal__leftPart {

        h4 {
            margin-bottom: 0;
        }
    }

    #portal {
        h2 {
            text-align: left;
            margin-bottom: size(20);
            font-size: size(28);
        }
    }

    .modal__rightPart {

        h2 {
            font-size: size(18);
            font-weight: $font-weight-bold;
            margin-bottom: size(55);
            margin-top: size(5);
        }
    }
}