.folders__addButton {
    @include fontMedium;
    font-size: size(18);
    color: $Valencia;
    cursor: pointer;
    
    i {
        margin-right: 13px;
    }

    &Inverted {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #FFFFFF;
        background-color: $Valencia;
        font-size: size(16);
        text-align: center;
        line-height: 20px;
        position: relative;
        cursor: pointer;

        i {
            @include center-absolute;
            color: $White;

        }
    }

}