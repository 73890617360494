﻿.creationDossier__budget {
	&SubtitleTotal {
		margin: 15px 0 45px;
		font-size: size(16);
	}

	.inputFile__infos--fileName {
		display: inline-block;
		margin-top: 10px;
		margin-right: 5px;
	}

	.textarea__item textarea {
		max-width: 100%;
		margin-bottom: 0;
	}

	.textarea__item {
		margin-bottom: 5px;
	}

	.budgetInfo {
		text-align: right;
		color: $Mine_Shaft_With_Opacity;
		font-size: size(16);

		&--error { color: $Valencia }
	}
}
