.toggle {
	display: flex;
	align-items: center;
	position: relative;

	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 0;
		height: 0;
		opacity: .000001;
	}

	label {
		@include fontBook;
		padding-right: 10px;
		color: $Mine_Shaft_With_Opacity;
		font-size: size(12);
	}

	input:checked~.toggle__item {
		background-color: $Valencia;

		.toggle__item-button {
			left: 21px;
			right: 3px;
			background-color: $White;

			i {
				padding: 4px 4px;
				color: $Valencia;
			}
		}
	}
}

.toggle__item {
	position: relative;
	width: 35px;
    height: 20px;
    padding: 2px 4px;
	border-radius: 13px;
	border: solid 1px $Valencia;
	background-color: $White;
	cursor: pointer;
	transition: background-color .2s ease;
	  
	&-button {
		position: absolute;
		left: 4px;
		right: 21px;
		top: 3px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: $Valencia;
		transition: all .2s ease;
		@include center-flex;

		i {
			color: $White;
			font-size: size(12);
			font-weight: 300;
		}
	}
}
