.mailboxsearchbar {
    flex-grow: 1;
    margin-bottom: 30px;
    font-size: size(16);
    padding-left: size(25);

    &:before {
        background-color: transparent !important;
    }

    i {
        color: $Valencia;
    }

    input {
        @include fontBook;
        @include input-reset;
        margin-left: size(10);
        color: $Mine_Shaft_With_Opacity;
    }
}