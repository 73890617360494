.textarea {
	width: 100%;
    resize: none;
    min-height: 150px;
    border-radius: 0;
	padding: 10px;
	box-sizing: border-box;
	
	&:focus {
		border: 1px solid $Mine_Shaft;
		outline: none;
	}
}
