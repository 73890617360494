﻿.creationDossier__lieuxDates {
	.inputReadOnly {
		margin-top: 0;
		padding-bottom: 0;

		label {
			color: $Mine_Shaft;
			font-size: 18px;
		}
		p {
			margin: 15px;
			line-height: 1;
			color: $Mine_Shaft_With_Opacity;
			font-size: 14px;
		}
	}
}
