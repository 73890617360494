.infosBancaires__item {
    @include flex-content-spaceBetween;

    .input {
        flex-basis: 45%;
    }
}

@media screen and (max-width: 768px) {
    .infosBancaires__item {
        flex-direction: column;
    }
}