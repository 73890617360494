input:disabled + .newCheckbox {
	opacity: .5;
	background: #f5f5f5;
	cursor: auto;
}

.input {
	position: relative;
	margin-top: size(20);

	label {
		font-size: size(15);
		color: rgba(51, 51, 51, 0.8);
		@include fontBook;

	}

	input {
		@include fontBook;
		display: block;
		margin: 5px 0;
		border: none;
		border-bottom: 1px solid $Mine-Shaft;
		height: 25px;
		width: 100%;

		&::placeholder {
			@include fontBookItalic;
			font-size: size(14);
		}

		&:focus {
			outline: none;
		}

		&:disabled {
			background: transparent;
		}
	}
}

.input__item {
	position: relative;

	svg {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0, -50%);
	}

	.input-item__icon-button {
		cursor: pointer;
	}

	.input__item--icon-button {
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 0;
	}
}

/** dataList */
.input__dataList {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	margin-top: 8px;
	padding: 3px 0;
	border-radius: 4px;
	box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
	background: $White;
	overflow-y: auto;
	font-size: size(14);
	z-index: 3;

	&--isOpen {
		display: block;
	}

	li {
		position: relative;
		padding: 10px 15px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow-x: hidden;
		text-align: left;

		&:hover {
			background-color: #f4c7ca;
			cursor: default;
			white-space: pre-wrap;		}

		&:focus,
		&:active {
			background-color: $Valencia;
			color: $White;
		}
	}
}

.overlay__dataList {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;

	&--isOpen {
		display: block;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 $White inset;
	transition: background-color 50s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 $White inset !important;
}

// Hide arrow buttons on inputs number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input__errorMessage {
	position: absolute;
	bottom: 0;
	left: 0;
	color: $Valencia;
	font-size: size(15);

	&--selectBox {
		bottom: auto;
	}
}


// ---------- INPUT FILTRES => Filtres situés dans le composant Filtres ----------
.filter__input {
	position: relative;
	text-align: center;

	input {
		&::placeholder {
			font-size: size(15);
			color: $Mine_Shaft_With_Opacity_Plus;
		}

		&:focus {
			outline: none;
		}

		&:disabled {
			background: transparent;
		}

		height: 15px;
		width: calc(100% - 25px);
		margin: 5px 0;
		border: none;
	}
}