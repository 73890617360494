.login {
	@include center-flex;
	width: 100%;

	.input__errorMessage { position: static; }
}

#portal {
	min-height: 100vh;

	.modal {
		max-width: 860px;
		width: 100%;
		min-height: 495px;
	}

	.modal__leftPart {
		min-width: 410px;
	}

	form {
		@include flex-column-contentBetween;
		flex: 1;
	}
}

#signup {
	.modal {
		margin: 40px 0;
		min-height: calc(100vh - 80px);
		max-width: 1120px;
		width: 100%;
	}
	
	/** Fix des messages d'erreur sur la step signup */
	.infosJuridiques__item,
	.contact__item {
		.input__errorMessage {
			bottom: auto;
			top: 55px;
			line-height: 1;
		}
	}
	.contact__email {
		.input__errorMessage {
			bottom: -20px;
		}
	}
}

.modal {
	@include flex-row;
	box-shadow: 0.8px 1.8px 9px 0 rgba(51, 51, 51, 0.12);
	box-sizing: border-box;
}

.modal__item {
	padding: size(30);
}

.modal__itemErrors {
	margin-bottom: 20px
}

.portal__lang {
	@include flex-content-end;
	padding-bottom: size(30);

	.lang {
		position: relative;
	}

	select {
		position: absolute;
		opacity: 0.0001;
		@include fontBold;
		font-size: size(14);
		cursor: pointer;
		z-index: 10;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.languageItem {
		padding: 10px;
	}

	span {
		color: $Black;
		padding-right: 20px;
		border: 3px solid transparent;
		background-color: transparent;
		user-select: none;
		font-size: size(13);
		@include fontBold;
		letter-spacing: 1px;

		&:after {
			position: absolute;
			content: "";
			top: calc(50% - 3px);
			right: 4px;
			border: 6px solid transparent;
			border-color: $Black transparent transparent transparent;
		}
	}
}

.background__container {
	width: 100%;
	height: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
	display: table;

	.background__image {
		display: table-cell;
		width: 375px;
		height: 299px;
		background: url(../../assets/img/spedidam_connexion_layer1.png) left bottom no-repeat,
			url(../../assets/img/spedidam_connexion_layer2.png) right top no-repeat,
			$Valencia;
	}
}

.modal__content {
	position: relative;
	height: 100%;
	z-index: 1;
	@include flex-column;
}

.modal__content-wrapper {
	overflow: hidden;
	height: 100%;

	>div {
		height: calc(100% - 80px);
	}
}

.scroll {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	padding-right: size(15);
}

.modal__leftPart {
	position: relative;
	box-sizing: border-box;
	flex: 0 0 40%;
	background-color: $Valencia;
	color: $White;
	@include flex-column;

	img {
		width: 101px;
	}

	p {
		font-size: size(14);
	}

	span {
		font-size: size(14)
	}

	a {
		color: $White;
	}

	.modal__footer {
		flex: 1;
		@include flex-column-contentEnd;
	}
}

.modal__rightPart {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	background: url(../../assets/img/portail/spedidam_waves.png) bottom no-repeat,
		$White;
	background-size: 100% 80px;
	color: $Mine_Shaft;
	@include flex-column;
}

.previousStep {
	display: none;
}

.modal__helper {
	@include fontBookItalic;
	font-size: size(14);
	color: $Dove_Gray;
}

// ---------- VALIDATION DU MOT DE PASSE ----------
.passwordValidated {
	text-align: center;

	img {
		width: 210px;
		margin-bottom: size(20);
	}
}


// ---------- RESPONSIVE ----------
@media screen and (max-width: 768px) {
	#signup {
		.modal { margin: 0; }
	}

	#portal {
		.modal__leftPart {
			min-width: unset;
		}
	}

	.modal__leftPart--footer {
		display: none;
	}

	.login {
		background-color: $Valencia;
		display: initial;
		height: 100%;
		top: unset;
		transform: unset;
		position: static;

		.modal {
			max-width: unset;
			height: 100vh;
			background-color: $Valencia;
			flex-direction: column;
			position: relative;
		}

		.modal__leftPart {
			flex: 0 0 auto;
		}

		.background__container {
			bottom: -200px;

			.background__image {
				background: url(../../assets/img/spedidam_connexion_responsive.png) no-repeat,
					$Valencia;
				background-position-x: right;
				background-position-y: inherit;
			}
		}
	}

	.modal__item {
		padding: size(20) size(30);
	}

	.modal__content-wrapper {
		margin-bottom: 30px;

		>div {
			height: unset;
		}
	}

	.modal__rightPart {
		@include flex-column;
		flex: 1;
		border-top-left-radius: 50px;
		border-top-right-radius: 50px;
		position: static;
		z-index: 1;
	}

	.portal__lang {
		position: absolute;
		top: size(20);
		right: size(60);
		padding-bottom: 0;

		span {
			color: $White;
			background-color: $Valencia;
			font-size: 12px;

			&:after {
				border-color: $White transparent transparent transparent;
			}
		}
	}

	.previousStep {
		display: block;
		position: absolute;
		margin-top: size(25);
		color: $Valencia;
		background: none;
	}

	.scroll {
		height: unset;
		padding: unset;
	}
}


/** Modal component **/
.modalComponent {
	@include center-flex;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 20;
	background: $Mine_Shaft_With_Opacity_Plus;

	.modalComponent__content {
		width: 590px;
		padding: 35px;
		background: $White;

		&--sidePicture {
			@include vertical-flex;
		}
	}

	.modalComponent__picture {
		margin-right: 35px
	}

	.modalComponent__footer {
		@include flex-center-spaceBetween;
		margin-top: 25px;

		button:first-child {
			background: transparent;
			color: $Valencia;
			border: 1px solid $Valencia;
		}
	}

	.modalComponent__body {
		.modalComponentBody__content {
			@include flex-column;

			.textarea {
				width: auto;
				flex-basis: 100%;
			}
		}
	}
}