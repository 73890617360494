//GENERATEUR DE NOM : chir.ag/projects/name-that-color/

$Black: #000000 !default;
$White: #FFFFFF !default;

// RED
$Valencia: #d93943 !default;
$Valencia_With_Opacity: rgba(217, 57, 67, 0.2) !default;
$Valencia_With_Less_Opacity: rgba(217, 57, 67, 0.5) !default;
$Valencia_Varient_With_Opacity: rgba(217, 57, 67, 0.9) !default;
$Guardsman_Red: #C90000 !default;
$Guardsman_Red_With_Opacity: rgba(201, 0, 0, 0.2) !default;
$Guardsman_Red_With_Less_Opacity: rgba(201, 0, 0, 0.5) !default;
$Sea_Pink: #ec9ca1;
$Valencia_2: #d54b35 !default;
$Valencia_2_With_Opacity: rgba(213, 74, 53, 0.2) !default;
$Valencia_2_With_Less_Opacity: rgba(213, 74, 53, 0.5) !default;

// ORANGE
$Vermilion: #FF4A0B;
$Vermilion_With_Opacity: rgba(255, 74, 11, 0.2) !default;
$Vermilion_With_Less_Opacity: rgba(255, 74, 11, 0.5) !default;
$Bamboo: #e86506 !default;
$Bamboo_With_Opacity: rgba(232, 101, 6, 0.2) !default;
$Bamboo_With_Less_Opacity: rgba(232, 101, 6, 0.5) !default;

//GREEN
$Fun_Green: #009029;
$Fun_Green_With_Opacity: rgba(0, 144, 41, 0.2) !default;
$Fun_Green_With_Less_Opacity: rgba(0, 144, 41, 0.5) !default;
$Malachite: #08BD15 !default;
$Green_With_Opacity: rgba(8, 189, 21, 0.2) !default;
$Green_With_Less_Opacity: rgba(8, 189, 21, 0.5) !default;
$Niagara: #08bd88 !default;
$Niagara_With_Opacity: rgba(8, 189, 136, 0.2) !default;
$Niagara_With_Less_Opacity: rgba(8, 189, 136, 0.5) !default;
$Sea_Green: #339E4C !default;

//PURPLE
$Gulf_Blue: #050963 !default;
$Gulf_Blue_With_Opacity: rgba(5, 9, 99, 0.2) !default;
$Gulf_Blue_With_Less_Opacity: rgba(5, 9, 99, 0.5) !default;

$Medium_Purple: #9B71E0 !default;

// GREY
$Mine_Shaft: #111 !default;
$Mine_Shaft_With_Opacity: rgba(51, 51, 51, 0.8) !default;
$Mine_Shaft_With_Opacity_Plus: rgba(32, 14, 14, 0.5) !default;
$Dove_Gray: #333 !default;
$Gray: $Dove_Gray !default;
$Gallery: #ECECEC !default;
$Selago: #F8FBFE !default;
$Alabaster: #FAFAFA !default;

// BLUE
$Klein_Blue: #002AB2 !default;