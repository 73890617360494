/** Pagination of tables */

.pagination {
	@include flex-center-spaceBetween;
	font-size: size(15);
	margin: 20px 30px;
	flex-wrap: wrap;

	ul { display: flex; }
}

.pagination__selection {
	@include vertical-flex;
	flex-shrink: 0;
    margin-right: 10px;

	label { margin-right: 5px; }

	.input {
		width: 50px;
		margin-top: 0;

		label { display: none; }
	}
}

.pagination__results {
	flex-shrink: 0;
}

.pagination__nav {
	overflow-x: auto;

	.pagination__button {
		@include fontBook;
		@include btn-reset;
		color: $Mine_Shaft; 
		margin: 0px;
		padding: 10px;
	
		&--active {
			@include fontBold;
			background-color: #5B788C;
		}

		&--disabled {
			opacity: .5;
		}
	}

	.inputSelect { top: auto; bottom: 100%; }
}